import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import FormProvider from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import * as Yup from 'yup';
import { currentUser } from 'src/utils/utils';
import { createGameRegistration, getAllGames } from 'src/api/services/sport-registration.services';
import PrimarySchoolForm from './school-types/primary-school';
import SecondarySchoolForm from './school-types/secondary-school';
import PlayerInputs from './players/player-inputs';

const MAX_PLAYERS = 10;
const MIN_PLAYERS = 10;
const defaultValues = {
  isPrimarySchool: false,
  isSecondarySchool: false,
  primarySchool: {
    name: '',
    udise: '',
    address: '',
    pincode: '',
    district: null,
    phone: '',
    email: '',
    principal: '',
    principalPhone: '',
    principalEmail: '',
    nodelTeacher: '',
    nodelTeacherPhone: '',
    paycenter: '',
    crc: '',
    brc: '',
    games: [],
    // gender: {
    //   male: false,
    //   female: false,
    // },
    // malePlayers: [{ name: '', std: '', grNo: '' }],
    // femalePlayers: [{ name: '', std: '', grNo: '' }],
  },
  secondarySchool: {
    name: '',
    ssindex: '',
    address: '',
    pincode: '',
    district: null,
    phone: '',
    principal: '',
    principalPhone: '',
    principalEmail: '',
    nodelTeacher: '',
    nodelTeacherPhone: '',
    email: '',
    qdc: '',
    svsc: '',
    games: [],
    // gender: {
    //   male: false,
    //   female: false,
    // },
    // malePlayers: [{ name: '', std: '', grNo: '' }],
    // femalePlayers: [{ name: '', std: '', grNo: '' }],
  },
};

const RegisterSchema = Yup.object().shape({
  isPrimarySchool: Yup.boolean(),
  isSecondarySchool: Yup.boolean(),
  primarySchool: Yup.object().when('isPrimarySchool', {
    is: true,
    then: () =>
      Yup.object().shape({
        name: Yup.string().required('School name is required'),
        udise: Yup.string()
          .min(12, 'UDISE number should not less than 12 digits')
          .max(12, 'UDISE number should not exceed 12 digits')
          .required('Enter UDISE number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        address: Yup.string().required('Address is required'),
        pincode: Yup.string().matches(/^\d{6}$/, 'PIN code must be exactly 6 digits'),
        district: Yup.object()
          .test(
            'required',
            'District is required!',
            (value) => value && (value?.value !== '' || value?.value !== null)
          )
          .nullable(),
        phone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        principal: Yup.string()
          .matches(/^[A-Za-z\s]+$/, 'Principal name should only contain alphabets')
          .required('Principal name is required'),
        principalPhone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        principalEmail: Yup.string().email('Invalid email').required('Principal email is required'),
        nodelTeacher: Yup.string()
          .matches(/^[A-Za-z\s]+$/, 'Nodel teacher name should only contain alphabets')
          .required('Nodel teacher name is required'),
        nodelTeacherPhone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        paycenter: Yup.string().required('Paycenter is required'),
        crc: Yup.string().required('CRC is required'),
        brc: Yup.string().required('BRC is required'),
        games: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required('Game name is required'),
              gender: Yup.object()
                .shape({
                  male: Yup.boolean(),
                  female: Yup.boolean(),
                })
                .test(
                  'at-least-one-gender',
                  'At least one gender must be selected',
                  (value) => value.male || value.female
                ),
              malePlayers: Yup.array().when('gender.male', {
                is: true,
                then: () =>
                  Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string()
                          .matches(/^[A-Za-z\s]+$/, 'Player name should only contain alphabets')
                          .required('Player name is required'),
                        std: Yup.string().required('Std is required'),
                        grNo: Yup.string().required('GR No is required'),
                      })
                    )
                    .min(10, 'At least 10 male players are required'),
                otherwise: () => Yup.array().of(Yup.object()),
              }),
              femalePlayers: Yup.array().when('gender.female', {
                is: true,
                then: () =>
                  Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string()
                          .matches(/^[A-Za-z\s]+$/, 'Player name should only contain alphabets')
                          .required('Player name is required'),
                        std: Yup.string().required('Std is required'),
                        grNo: Yup.string().required('GR No is required'),
                      })
                    )
                    .min(10, 'At least 10 female players are required'),
                otherwise: () => Yup.array().of(Yup.object()),
              }),
            })
          )
          .min(1, 'At least one game must be selected'),
      }),
    otherwise: () => Yup.object(),
  }),
  secondarySchool: Yup.object().when('isSecondarySchool', {
    is: true,
    then: () =>
      Yup.object().shape({
        name: Yup.string().required('Secondary School Name is required'),
        ssindex: Yup.string()
          .matches(/^\d+\.\d+$/, 'SSINDEX must be a decimal number')
          .required('SSINDEX is required'),
        address: Yup.string().required('Address is required'),
        pincode: Yup.string()
          .matches(/^\d{6}$/, 'PIN code must be exactly 6 digits')
          .required('Enter Pincode'),
        district: Yup.object()
          .test(
            'required',
            'District is required!',
            (value) => value && (value?.value !== '' || value?.value !== null)
          )
          .nullable(),
        phone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        principal: Yup.string()
          .matches(/^[A-Za-z\s]+$/, 'Principal name should only contain alphabets')
          .required('Principal name is required'),
        principalPhone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        principalEmail: Yup.string().email('Invalid email').required('Principal Email is required'),
        nodelTeacher: Yup.string()
          .matches(/^[A-Za-z\s]+$/, 'Nodel teacher name should only contain alphabets')
          .required('Nodel teacher name is required'),
        nodelTeacherPhone: Yup.string()
          .min(10, 'Phone number should not less than 10 digits')
          .max(10, 'Phone number should not exceed 10 digits')
          .required('Enter Phone number')
          .matches(/^[0-9]*$/, 'Please enter only digits.'),
        email: Yup.string().email('Invalid email').required('School Email is required'),
        qdc: Yup.string().required('QDC name/number is required'),
        svsc: Yup.string().required('SVS name/number is required'),
        games: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required('Game name is required'),
              gender: Yup.object()
                .shape({
                  male: Yup.boolean(),
                  female: Yup.boolean(),
                })
                .test(
                  'at-least-one-gender',
                  'At least one gender must be selected',
                  (value) => value.male || value.female
                ),
              malePlayers: Yup.array().when('gender.male', {
                is: true,
                then: () =>
                  Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string()
                          .matches(/^[A-Za-z\s]+$/, 'Player name should only contain alphabets')
                          .required('Player name is required'),
                        std: Yup.string().required('Std is required'),
                        grNo: Yup.string().required('GR No is required'),
                      })
                    )
                    .min(10, 'At least 10 male players are required'),
                otherwise: () => Yup.array().of(Yup.object()),
              }),
              femalePlayers: Yup.array().when('gender.female', {
                is: true,
                then: () =>
                  Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string()
                          .matches(/^[A-Za-z\s]+$/, 'Player name should only contain alphabets')
                          .required('Player name is required'),
                        std: Yup.string().required('Std is required'),
                        grNo: Yup.string().required('GR No is required'),
                      })
                    )
                    .min(10, 'At least 10 female players are required'),
                otherwise: () => Yup.array().of(Yup.object()),
              }),
            })
          )
          .min(1, 'At least one game must be selected'),
      }),
    otherwise: () => Yup.object(),
  }),
});

export default function NewGameRegistrationForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLocales();
  const [gamesOptions, setGamesOptions] = useState([]);

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!data.isPrimarySchool && !data.isSecondarySchool) {
        enqueueSnackbar('Please select at least one school type', { variant: 'error' });
        return;
      }
      const userId = currentUser()?.id;
      const formattedData = {
        ...data,
        userId,
        primarySchool: data.isPrimarySchool
          ? {
              ...data.primarySchool,
              district: data.primarySchool?.district?.value,
            }
          : null,
        secondarySchool: data.isSecondarySchool
          ? {
              ...data.secondarySchool,
              district: data.secondarySchool?.district?.value,
            }
          : null,
      };

      await createGameRegistration(formattedData);
      enqueueSnackbar('Registration submitted successfully', { variant: 'success' });
      reset();

      // Navigate or reset form as needed
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(`Error: ${error.response.data.message || 'Server error'}`, {
          variant: 'error',
        });
      } else if (error.request) {
        enqueueSnackbar('Network error: No response received from server', { variant: 'error' });
      } else {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      }
    }
  });

  const handleSchoolTypeChange = (event) => {
    const { name, checked } = event.target;
    setValue(name, checked);
  };

  const handleGameChange = (event, schoolType) => {
    const { value, checked } = event.target;
    const games = values[schoolType].games;

    if (checked) {
      setValue(`${schoolType}.games`, [
        ...games,
        {
          name: value,
          gender: { male: false, female: false },
          malePlayers: [],
          femalePlayers: [],
          id: gamesOptions?.find((game) => game?.gameName === value)?.id,
        },
      ]);
      clearErrors(`${schoolType}.games`);
    } else {
      setValue(
        `${schoolType}.games`,
        games.filter((game) => game.name !== value)
      );
    }
  };

  const handleGenderChange = (gender, schoolType, gameIndex) => {
    const currentValue = values[schoolType].games[gameIndex].gender[gender];
    setValue(`${schoolType}.games[${gameIndex}].gender.${gender}`, !currentValue);
    clearErrors(`${schoolType}.games[${gameIndex}].gender`);
    // If gender is being checked, ensure there's at least one player
    if (!currentValue) {
      const players = values[schoolType].games[gameIndex][`${gender}Players`];
      if (players.length === 0) {
        setValue(`${schoolType}.games[${gameIndex}].${gender}Players`, [
          { name: '', std: '', grNo: '' },
        ]);
      }
    }
  };

  const addPlayer = (gender, schoolType, gameIndex) => {
    const players = values[schoolType].games[gameIndex][`${gender}Players`];
    if (players.length >= MAX_PLAYERS) {
      enqueueSnackbar(`Maximum number of ${gender} players reached`, { variant: 'warning' });
      return;
    }

    if (players?.length === 9) {
      clearErrors(`${schoolType}.games[${gameIndex}].${gender}Players`);
    }

    setValue(`${schoolType}.games[${gameIndex}].${gender}Players`, [
      ...players,
      { name: '', std: '', grNo: '' },
    ]);
  };

  const removePlayer = (gender, playerIndex, schoolType, gameIndex) => {
    const players = values[schoolType].games[gameIndex][`${gender}Players`];
    setValue(
      `${schoolType}.games[${gameIndex}].${gender}Players`,
      players.filter((_, i) => i !== playerIndex)
    );
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await getAllGames();
        setGamesOptions(response?.data || []);
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <Typography variant="h6">School Types</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isPrimarySchool}
                onChange={handleSchoolTypeChange}
                name="isPrimarySchool"
              />
            }
            label="Primary School"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isSecondarySchool}
                onChange={handleSchoolTypeChange}
                name="isSecondarySchool"
              />
            }
            label="Secondary School"
          />
        </Grid>

        {values.isPrimarySchool && (
          <>
            <PrimarySchoolForm />

            <Grid item xs={12}>
              <Typography variant="h6">Games</Typography>

              {/* {gamesOptions?.map((game) => (
                <FormControlLabel
                  key={game.id}
                  control={
                    <Checkbox
                      name="primarySchool.games"
                      checked={values.primarySchool.games.some((g) => g.id === game.id)}
                      onChange={(event) => handleGameChange(event, 'primarySchool')}
                      value={game.id}
                    />
                  }
                  label={game.gameName}
                />
              ))} */}
              {gamesOptions?.map((game) => (
                <FormControlLabel
                  key={game.name}
                  control={
                    <Checkbox
                      name="primarySchool.games"
                      checked={values?.primarySchool?.games?.some((g) => g.name === game?.gameName)}
                      onChange={(event) => handleGameChange(event, 'primarySchool')}
                      value={game?.gameName}
                    />
                  }
                  label={game?.gameName}
                />
              ))}

              {errors?.primarySchool?.games && (
                <Typography fontSize={12} color="error">
                  {errors.primarySchool.games.message}
                </Typography>
              )}
            </Grid>

            {values?.primarySchool?.games?.map((game, gameIndex) => (
              <Grid item xs={12} key={game.name}>
                <Typography variant="h6">{game.name}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Gender</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.gender.male}
                          onChange={() => handleGenderChange('male', 'primarySchool', gameIndex)}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.gender.female}
                          onChange={() => handleGenderChange('female', 'primarySchool', gameIndex)}
                        />
                      }
                      label="Female"
                    />
                    {errors?.primarySchool?.games?.[gameIndex]?.gender && (
                      <Typography color="error" variant="caption">
                        {errors.primarySchool.games[gameIndex].gender.message}
                      </Typography>
                    )}
                  </Grid>
                  {game.gender.male && (
                    <Grid item xs={12}>
                      <PlayerInputs
                        players={game.malePlayers}
                        addPlayer={() => addPlayer('male', 'primarySchool', gameIndex)}
                        removePlayer={(index) =>
                          removePlayer('male', index, 'primarySchool', gameIndex)
                        }
                        maxPlayers={MAX_PLAYERS}
                        gender="male"
                        gameIndex={gameIndex}
                        schoolType="primarySchool"
                      />
                      {errors?.primarySchool?.games?.[gameIndex]?.malePlayers && (
                        <Typography color="error" variant="caption">
                          {errors.primarySchool.games[gameIndex].malePlayers.message}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {game.gender.female && (
                    <Grid item xs={12}>
                      <PlayerInputs
                        players={game.femalePlayers}
                        addPlayer={() => addPlayer('female', 'primarySchool', gameIndex)}
                        removePlayer={(index) =>
                          removePlayer('female', index, 'primarySchool', gameIndex)
                        }
                        maxPlayers={MAX_PLAYERS}
                        gender="female"
                        gameIndex={gameIndex}
                        schoolType="primarySchool"
                      />
                      {errors?.primarySchool?.games?.[gameIndex]?.femalePlayers && (
                        <Typography color="error" variant="caption">
                          {errors.primarySchool.games[gameIndex].femalePlayers.message}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </>
        )}

        {values?.isSecondarySchool && (
          <>
            <SecondarySchoolForm />

            <Grid item xs={12}>
              <Typography variant="h6">Games</Typography>
              {gamesOptions?.map((game) => (
                <FormControlLabel
                  key={game?.gameName}
                  control={
                    <Checkbox
                      name="secondarySchool.games"
                      checked={values?.secondarySchool?.games?.some(
                        (g) => g.name === game?.gameName
                      )}
                      onChange={(event) => handleGameChange(event, 'secondarySchool')}
                      value={game?.gameName}
                    />
                  }
                  label={game?.gameName}
                />
              ))}
              {errors?.secondarySchool?.games && (
                <Typography fontSize={12} color="error">
                  {errors?.secondarySchool?.games?.message}
                </Typography>
              )}
            </Grid>

            {values?.secondarySchool?.games?.map((game, gameIndex) => (
              <Grid item xs={12} key={game.name}>
                <Typography variant="h6">{game.name}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Gender</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.gender.male}
                          onChange={() => handleGenderChange('male', 'secondarySchool', gameIndex)}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={game.gender.female}
                          onChange={() =>
                            handleGenderChange('female', 'secondarySchool', gameIndex)
                          }
                        />
                      }
                      label="Female"
                    />
                    {errors?.secondarySchool?.games?.[gameIndex]?.gender && (
                      <Typography color="error" variant="caption">
                        {errors.secondarySchool.games[gameIndex].gender.message}
                      </Typography>
                    )}
                  </Grid>
                  {game?.gender?.male && (
                    <Grid item xs={12}>
                      <PlayerInputs
                        players={game?.malePlayers}
                        addPlayer={() => addPlayer('male', 'secondarySchool', gameIndex)}
                        removePlayer={(index) =>
                          removePlayer('male', index, 'secondarySchool', gameIndex)
                        }
                        maxPlayers={MAX_PLAYERS}
                        gender="male"
                        gameIndex={gameIndex}
                        schoolType="secondarySchool"
                      />
                      {errors?.secondarySchool?.games?.[gameIndex]?.malePlayers && (
                        <Typography color="error" variant="caption">
                          {errors.secondarySchool.games[gameIndex].malePlayers.message}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {game?.gender?.female && (
                    <Grid item xs={12}>
                      <PlayerInputs
                        players={game?.femalePlayers}
                        addPlayer={() => addPlayer('female', 'secondarySchool', gameIndex)}
                        removePlayer={(index) =>
                          removePlayer('female', index, 'secondarySchool', gameIndex)
                        }
                        maxPlayers={MAX_PLAYERS}
                        gender="female"
                        gameIndex={gameIndex}
                        schoolType="secondarySchool"
                      />
                      {errors?.secondarySchool?.games?.[gameIndex]?.femalePlayers && (
                        <Typography color="error" variant="caption">
                          {errors.secondarySchool.games[gameIndex].femalePlayers.message}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </>
        )}

        <Grid item xs={12} textAlign="end">
          <LoadingButton
            sx={{ maxWidth: 'fit-content', mr: 2 }}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>

          <Button
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              reset();
              navigate(paths.hssfPaths.gameRegistration.list);
            }}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
