import { ROLES } from 'src/utils/utils';

export const core_team_data = [
  {
    value: 'sachiv',
    title: 'Sachiv',
    contact_no: '1234567890',
    guj_title: 'સચિવ',
    name: 'Aarav Agarwal',
    guj_name: 'આરવ અગ્રવાલ',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '001',
    team_role: 'core',
  },
  {
    value: 'sahsachiv',
    title: 'Sahsachiv',
    contact_no: '1234567890',
    guj_title: 'સહ સચિવ',
    name: 'Aanya Ahuja',
    guj_name: 'અનન્યા આહુજા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '002',
    team_role: 'core',
  },
  {
    value: 'dharmik',
    title: 'Dharmik Institutions',
    guj_title: 'ધાર્મિક (મઠ મંદિર)',
    name: 'Barkha Nadkarni',
    guj_name: 'બરખા નાડકર્ણી',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '003',
    team_role: 'ayam',
  },
  {
    value: 'shikshan_sanstha_sampark',
    title: 'Educational Institutions',
    guj_title: 'શૈક્ષણિક સંસ્થા',
    name: 'Gargee Panicker',
    guj_name: 'ગાર્ગી પનીકર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '004',
    team_role: 'ayam',
  },
  {
    value: 'samajik_sanstha_sampark',
    title: 'Socialist (NGOs)',
    guj_title: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
    name: 'Divit Nayar',
    guj_name: 'દિવિત નાયર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '005',
    team_role: 'ayam',
  },
  {
    value: 'yuva_shakti',
    title: 'Youth Wings',
    guj_title: 'યુવા શક્તિ',
    name: 'Chandni Narayan',
    guj_name: 'ચાંદની નારાયણ',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '006',
    team_role: 'ayam',
  },
  {
    value: 'nari_shakti',
    title: 'Female Wings',
    guj_title: 'નારી શક્તિ',
    name: 'Avishi Mukhopadhyay',
    guj_name: 'અવશી મુખોપાધ્યાય',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '007',
    team_role: 'ayam',
  },
  {
    value: 'parivar_kshetra',
    title: 'Family Organizations',
    guj_title: 'પરિવાર ક્ષેત્ર',
    name: 'Gayatri Iyer',
    guj_name: 'ગાયત્રી અય્યર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '008',
    team_role: 'ayam',
  },
  {
    value: 'corporate_sampark',
    title: 'Corporate Houses',
    guj_title: 'કોર્પોરેટ સંપર્ક',
    name: 'Yashvi Madan',
    guj_name: 'યશ્વી મદન',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '009',
    team_role: 'ayam',
  },
  {
    value: 'prachar_jaherat',
    title: 'Media / Social Media',
    guj_title: 'પ્રચાર - પ્રસાર',
    name: 'Vipul Jethava',
    guj_name: 'વિપુલ જેઠવા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '010',
    team_role: 'ayam',
  },
  {
    value: 'karyalaya',
    title: 'Office',
    guj_title: 'કાર્યાલય',
    name: 'Chintan Soya',
    guj_name: 'ચિંતન સોયા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '011',
    team_role: 'ayam',
  },
  {
    value: 'it',
    title: 'IT',
    guj_title: 'IT',
    name: 'Bhupendra Sharma',
    guj_name: 'ભૂપેન્દ્ર શર્મા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '012',
    team_role: 'ayam',
  },
];

export const core_team_data_1 = [
  {
    value: 'sayojak',
    title: 'Sayojak',
    guj_title: 'સયોજક',
    name: 'Bhupendra Sharma',
    guj_name: 'ભૂપેન્દ્ર શર્મા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    team_role: 'core',
  },
  {
    value: 'sahsyojak',
    title: 'Sah Syojak',
    guj_title: 'સહ સયોજક',
    name: 'Vipul Jethava',
    guj_name: 'વિપુલ જેઠવા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    team_role: 'core',
  },
  {
    value: 'dharmik',
    title: 'Dharmik Institutions',
    guj_title: 'ધાર્મિક (મઠ મંદિર)',
    name: 'Barkha Nadkarni',
    guj_name: 'બરખા નાડકર્ણી',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '003',
    team_role: 'ayam',
  },
  {
    value: 'shikshan_sanstha_sampark',
    title: 'Educational Institutions',
    guj_title: 'શૈક્ષણિક સંસ્થા',
    name: 'Gargee Panicker',
    guj_name: 'ગાર્ગી પનીકર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '004',
    team_role: 'ayam',
  },
  {
    value: 'samajik_sanstha_sampark',
    title: 'Socialist (NGOs)',
    guj_title: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
    name: 'Divit Nayar',
    guj_name: 'દિવિત નાયર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '005',
    team_role: 'ayam',
  },
  {
    value: 'yuva_shakti',
    title: 'Youth Wings',
    guj_title: 'યુવા શક્તિ',
    name: 'Chandni Narayan',
    guj_name: 'ચાંદની નારાયણ',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '006',
    team_role: 'ayam',
  },
  {
    value: 'nari_shakti',
    title: 'Female Wings',
    guj_title: 'નારી શક્તિ',
    name: 'Avishi Mukhopadhyay',
    guj_name: 'અવશી મુખોપાધ્યાય',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '007',
    team_role: 'ayam',
  },
  {
    value: 'parivar_kshetra',
    title: 'Family Organizations',
    guj_title: 'પરિવાર ક્ષેત્ર',
    name: 'Gayatri Iyer',
    guj_name: 'ગાયત્રી અય્યર',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '008',
    team_role: 'ayam',
  },
  {
    value: 'corporate_sampark',
    title: 'Corporate Houses',
    guj_title: 'કોર્પોરેટ સંપર્ક',
    name: 'Yashvi Madan',
    guj_name: 'યશ્વી મદન',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '009',
    team_role: 'ayam',
  },
  {
    value: 'prachar_jaherat',
    title: 'Media / Social Media',
    guj_title: 'પ્રચાર - પ્રસાર',
    name: 'Vipul Jethava',
    guj_name: 'વિપુલ જેઠવા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '010',
    team_role: 'ayam',
  },
  {
    value: 'karyalaya',
    title: 'Office',
    guj_title: 'કાર્યાલય',
    name: 'Chintan Soya',
    guj_name: 'ચિંતન સોયા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '011',
    team_role: 'ayam',
  },
  {
    value: 'it',
    title: 'IT',
    guj_title: 'IT',
    name: 'Bhupendra Sharma',
    guj_name: 'ભૂપેન્દ્ર શર્મા',
    contact: '9876543210',
    guj_contact: '૯૮૭૬૫૪૩૨૧૦',
    id: '012',
    team_role: 'ayam',
  },
];

// SECTIONS - data
export const sections_data = [
  {
    sections: 'Navsari',
    guj_sections: 'નવસારી',
    value: 'navsari',
    sayonjak_name: 'Vishanu Bhatt',
    guj_sayonjak_name: 'વિષ્ણુ ભટ્ટ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Kuldip Nagor',
    guj_sah_sayonjak_name: 'કુલદિપ નાગોર',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Surat',
    guj_sections: 'સુરત',
    value: 'surat',
    sayonjak_name: 'Dixit Varma',
    guj_sayonjak_name: 'દીક્ષિત વર્મા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Kanubhai Dodiya',
    guj_sah_sayonjak_name: 'કનુભાઈ ડોડીયા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Vadodra',
    guj_sections: 'વડોદરા',
    value: 'vadodra',
    sayonjak_name: 'Krishana Rathod',
    guj_sayonjak_name: 'ક્રિષ્ના રાઠોડ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Aanya Ahuja',
    guj_sah_sayonjak_name: 'અનન્યા આહુજા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Nadiyad',
    guj_sections: 'નડિયાદ',
    value: 'nadiyad',
    sayonjak_name: 'Valmik Krishana',
    guj_sayonjak_name: 'વાલ્મીક કૃષ્ણ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Vipul Jethava',
    guj_sah_sayonjak_name: 'વિપુલ જેઠવા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Karnavati - East (ahmedabad)',
    guj_sections: 'કર્ણાવતી - પૂર્વ (અમદાવાદ)',
    value: 'karnavati_east',
    sayonjak_name: 'Bhupendra Sharma',
    guj_sayonjak_name: 'ભૂપેન્દ્ર શર્મા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Avani Altal',
    guj_sah_sayonjak_name: 'અવની અલ્તાલ',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Karnavati - West (ahmedabad)',
    guj_sections: 'કર્ણાવતી - પશ્ચિમ (અમદાવાદ)',
    value: 'karnavati_west',
    sayonjak_name: 'Gaurav Gupta',
    guj_sayonjak_name: 'ગૌરવ ગુપ્તા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Ziva Maheshwari',
    guj_sah_sayonjak_name: 'ઝીવા મહેશ્વરી',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Gandhinagar',
    guj_sections: 'ગાંધીનગર',
    value: 'gandhinagar',
    sayonjak_name: 'Advik Malhotra',
    guj_sayonjak_name: 'અદ્વિક મલ્હોત્રા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Amaira Mehra',
    guj_sah_sayonjak_name: 'અમાયરા મેહરા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Mahesana',
    guj_sections: 'મહેસાણા',
    value: 'mahesana',
    sayonjak_name: 'Aneesh Menon',
    guj_sayonjak_name: 'અનીશ મેનન',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Anshul Mirza',
    guj_sah_sayonjak_name: 'અંશુલ મિર્ઝા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Banashkantha',
    guj_sections: 'બનાસકાંઠા',
    value: 'banashkantha',
    sayonjak_name: 'Mittal Aryansh',
    guj_sayonjak_name: 'મિત્તલ આર્યનશ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Vipul Jethava',
    guj_sah_sayonjak_name: 'વિપુલ જેઠવા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Surendranagar',
    guj_sections: 'સુરેન્દ્રનગર',
    value: 'surendranagar',
    sayonjak_name: 'Dixit Varma',
    guj_sayonjak_name: 'દીક્ષિત વર્મા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Aanya Ahuja',
    guj_sah_sayonjak_name: 'અનન્યા આહુજા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Bhavnagar',
    guj_sections: 'ભાવનગર',
    value: 'bhavnagar',
    sayonjak_name: 'Gaurav Gupta',
    guj_sayonjak_name: 'ગૌરવ ગુપ્તા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Amaira Mehra',
    guj_sah_sayonjak_name: 'અમાયરા મેહરા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Rajkot',
    guj_sections: 'રાજકોટ',
    value: 'rajkot',
    sayonjak_name: 'Mittal Aryansh',
    guj_sayonjak_name: 'મિત્તલ આર્યનશ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Vipul Jethava',
    guj_sah_sayonjak_name: 'વિપુલ જેઠવા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Junagadh',
    guj_sections: 'જુનાગઢ',
    value: 'junagadh',
    sayonjak_name: 'Gaurav Gupta',
    guj_sayonjak_name: 'ગૌરવ ગુપ્તા',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Amaira Mehra',
    guj_sah_sayonjak_name: 'અમાયરા મેહરા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
  {
    sections: 'Kachh',
    guj_sections: 'કચ્છ',
    value: 'kachh',
    sayonjak_name: 'Krishana Rathod',
    guj_sayonjak_name: 'ક્રિષ્ના રાઠોડ',
    sayonjak_contact: '9876543210',
    guj_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
    sah_sayonjak_name: 'Aanya Ahuja',
    guj_sah_sayonjak_name: 'અનન્યા આહુજા',
    sah_sayonjak_contact: '9876543210',
    guj_sah_sayonjak_contact: '૯૮૭૬૫૪૩૨૧૦',
  },
];

export const district_data = [
  {
    district_name: 'Ahmedabad',
    district_headquarters: 'Ahmedabad',
    district_value: 'karnavati',
    talukas: [
      { taluka_name: 'Ahmedabad' },
      { taluka_name: 'Kheda' },
      { taluka_name: 'Nadiad' },
      { taluka_name: 'Kathlal' },
      { taluka_name: 'Mahemdavad' },
      { taluka_name: 'Kapadvanj' },
      { taluka_name: 'Thasra' },
      { taluka_name: 'Mahudha' },
      { taluka_name: 'Galteshwar' },
      { taluka_name: 'Matar' },
      { taluka_name: 'Vaso' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Amreli',
    district_headquarters: 'Amreli',
    district_value: 'amreli',
    talukas: [
      { taluka_name: 'Amreli' },
      { taluka_name: 'Bagasara' },
      { taluka_name: 'Babra' },
      { taluka_name: 'Jafarabad' },
      { taluka_name: 'Rajula' },
      { taluka_name: 'Khambha' },
      { taluka_name: 'Dhari' },
      { taluka_name: 'Lathi' },
      { taluka_name: 'Savarkundla' },
      { taluka_name: 'Liliya' },
      { taluka_name: 'Kukavav' },
    ],
    number_of_talukas: 11,
  },
  {
    district_name: 'mahesana',
    district_headquarters: 'mahesana',
    district_value: 'mahesana',

    talukas: [{ taluka_name: 'mahesana' }],
    number_of_talukas: 1,
  },
  {
    district_name: 'Aravalli',
    district_headquarters: 'Modasa',
    district_value: 'modasa',

    talukas: [
      { taluka_name: 'Modasa' },
      { taluka_name: 'Bhiloda' },
      { taluka_name: 'Dhansura' },
      { taluka_name: 'Byad' },
      { taluka_name: 'Meghraj' },
      { taluka_name: 'Malpura' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Anand',
    district_headquarters: 'Anand',
    district_value: 'anand',

    talukas: [
      { taluka_name: 'Anand' },
      { taluka_name: 'Khambhat' },
      { taluka_name: 'Borsad' },
      { taluka_name: 'Petlad' },
      { taluka_name: 'Tarapur' },
      { taluka_name: 'Sojitra' },
      { taluka_name: 'Anklav' },
      { taluka_name: 'Umreth' },
    ],
    number_of_talukas: 8,
  },
  {
    district_name: 'Kachh',
    district_headquarters: 'Kachh',
    district_value: 'kachh',
    talukas: [
      { taluka_name: 'Bhuj' },
      { taluka_name: 'Bhachau' },
      { taluka_name: 'Anjar' },
      { taluka_name: 'Abadsa (Naliya)' },
      { taluka_name: 'Mandvi' },
      { taluka_name: 'Mundra' },
      { taluka_name: 'Rapar' },
      { taluka_name: 'Gandhidham' },
      { taluka_name: 'Lakhpat' },
      { taluka_name: 'Nakhtrana' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Kheda',
    district_headquarters: 'Nadiyad',
    district_value: 'nadiyad',
    talukas: [
      { taluka_name: 'Kheda' },
      { taluka_name: 'Nadiad' },
      { taluka_name: 'Kathlal' },
      { taluka_name: 'Mahemdavad' },
      { taluka_name: 'Kapadvanj' },
      { taluka_name: 'Thasra' },
      { taluka_name: 'Mahudha' },
      { taluka_name: 'Galteshwar' },
      { taluka_name: 'Matar' },
      { taluka_name: 'Vaso' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Gandhinagar',
    district_headquarters: 'Gandhinagar',
    district_value: 'gandhinagar',
    talukas: [
      { taluka_name: 'Gandhinagar' },
      { taluka_name: 'Kalol' },
      { taluka_name: 'Dahegam' },
      { taluka_name: 'Mansa' },
    ],
    number_of_talukas: 4,
  },
  {
    district_name: 'Gir Somnath',
    district_headquarters: 'Veraval',
    district_value: 'veraval',

    talukas: [
      { taluka_name: 'Veraval' },
      { taluka_name: 'Kodinar' },
      { taluka_name: 'Una' },
      { taluka_name: 'Sutrapada' },
      { taluka_name: 'Gir Gadha' },
      { taluka_name: 'Talala' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Chhotaudepur',
    district_headquarters: 'Chhotaudepur',
    district_value: 'chhotaudepur',

    talukas: [
      { taluka_name: 'Chhotaudepur' },
      { taluka_name: 'Sankheda' },
      { taluka_name: 'Jetpur-Pavi' },
      { taluka_name: 'Kwat' },
      { taluka_name: 'Bodeli' },
      { taluka_name: 'Naswadi' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Jamnagar',
    district_headquarters: 'Jamnagar',
    district_value: 'jamnagar',

    talukas: [
      { taluka_name: 'Jamnagar' },
      { taluka_name: 'Jamjodhpur' },
      { taluka_name: 'Jodia' },
      { taluka_name: 'Lalpur' },
      { taluka_name: 'Dhrol' },
      { taluka_name: 'Kalawad' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Junagadh',
    district_headquarters: 'Junagadh',
    district_value: 'junagadh',
    talukas: [
      { taluka_name: 'Junagadh City' },
      { taluka_name: 'Junagadh Rural' },
      { taluka_name: 'Bhesan' },
      { taluka_name: 'Keshod' },
      { taluka_name: 'Manavadar' },
      { taluka_name: 'Mendarda' },
      { taluka_name: 'Malia-Hatina' },
      { taluka_name: 'Mangrol' },
      { taluka_name: 'Visavadar' },
      { taluka_name: 'Vanthali' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Dang',
    district_headquarters: 'Ahwa',
    district_value: 'ahwa',

    talukas: [
      { taluka_name: 'Ahwa' },
      { taluka_name: 'congratulations' },
      { taluka_name: 'Subir' },
    ],
    number_of_talukas: 3,
  },
  {
    district_name: 'Tapi',
    district_headquarters: 'Vyara',
    district_value: 'vyara',

    talukas: [
      { taluka_name: 'Vyara' },
      { taluka_name: 'Dolvan' },
      { taluka_name: 'Kukarmunda' },
      { taluka_name: 'Songadh' },
      { taluka_name: 'Nizar' },
      { taluka_name: 'Valod' },
      { taluka_name: 'Uchhal' },
    ],
    number_of_talukas: 7,
  },
  {
    district_name: 'Dahod',
    district_headquarters: 'Dahod',
    district_value: 'dahod',

    talukas: [
      { taluka_name: 'Dahod' },
      { taluka_name: 'Zhalod' },
      { taluka_name: 'Dhanpur' },
      { taluka_name: 'Singwad' },
      { taluka_name: 'Fatehpura' },
      { taluka_name: 'Garbada' },
      { taluka_name: 'Devgarh Baria' },
      { taluka_name: 'Limkheda' },
      { taluka_name: 'Sanjeli' },
    ],
    number_of_talukas: 9,
  },
  {
    district_name: 'Devbhoomi Dwarka',
    district_headquarters: 'Khambhaliya',
    district_value: 'khambhaliya',

    talukas: [
      { taluka_name: 'Dwarka' },
      { taluka_name: 'Kalyanpur' },
      { taluka_name: 'Bhanwad' },
      { taluka_name: 'Khambhaliya' },
    ],
    number_of_talukas: 4,
  },
  {
    district_name: 'Narmada',
    district_headquarters: 'Rajpipla',
    district_value: 'rajpipla',

    talukas: [
      { taluka_name: 'Nandod' },
      { taluka_name: 'Sagbara' },
      { taluka_name: 'Dadiapada' },
      { taluka_name: 'Garudeshwar' },
      { taluka_name: 'Tilakwada' },
    ],
    number_of_talukas: 5,
  },
  {
    district_name: 'Navsari',
    district_headquarters: 'Navsari',
    district_value: 'navsari',
    talukas: [
      { taluka_name: 'Navsari' },
      { taluka_name: 'Gandevi' },
      { taluka_name: 'Chikhli' },
      { taluka_name: 'Vasanda' },
      { taluka_name: 'Jalalpore' },
      { taluka_name: 'Khergam' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Panchmahal',
    district_headquarters: 'Godhra',
    district_value: 'godhra',

    talukas: [
      { taluka_name: 'Godhra' },
      { taluka_name: 'Halol' },
      { taluka_name: 'Kalol' },
      { taluka_name: 'Ghoghamba' },
      { taluka_name: 'Jambughoda' },
      { taluka_name: 'Shehra' },
      { taluka_name: 'Morwa-Hadaf' },
    ],
    number_of_talukas: 7,
  },
  {
    district_name: 'Patan',
    district_headquarters: 'Patan',
    district_value: 'patan',

    talukas: [
      { taluka_name: 'Patan' },
      { taluka_name: 'Radhanpur' },
      { taluka_name: 'Siddhpur' },
      { taluka_name: 'Chanasma' },
      { taluka_name: 'Santalpur' },
      { taluka_name: 'Harij' },
      { taluka_name: 'Sami' },
      { taluka_name: 'Saraswati' },
      { taluka_name: 'Shankheshwar' },
    ],
    number_of_talukas: 9,
  },
  {
    district_name: 'Porbandar',
    district_headquarters: 'Porbandar',
    district_value: 'porbandar',

    talukas: [{ taluka_name: 'Porbandar' }, { taluka_name: 'Ranavav' }, { taluka_name: 'Kutiana' }],
    number_of_talukas: 3,
  },
  {
    district_name: 'Banashkantha',
    district_headquarters: 'Banashkantha',
    district_value: 'banashkantha',
    talukas: [
      { taluka_name: 'Palanpur' },
      { taluka_name: 'Tharad' },
      { taluka_name: 'Dhanera' },
      { taluka_name: 'Vav' },
      { taluka_name: 'Diodar' },
      { taluka_name: 'Deesa' },
      { taluka_name: 'Kankraj' },
      { taluka_name: 'Danta' },
      { taluka_name: 'Dantiwada' },
      { taluka_name: 'Vadgam' },
      { taluka_name: 'Lakhni' },
      { taluka_name: 'Bhabhar' },
      { taluka_name: 'Suigam' },
      { taluka_name: 'Amirgarh' },
    ],
    number_of_talukas: 14,
  },
  {
    district_name: 'Botad',
    district_headquarters: 'Botad',
    district_value: 'botad',

    talukas: [
      { taluka_name: 'Botad' },
      { taluka_name: 'Gadha' },
      { taluka_name: 'Barwala' },
      { taluka_name: 'Ranpur' },
    ],
    number_of_talukas: 4,
  },
  {
    district_name: 'Bharuch',
    district_headquarters: 'Bharuch',
    district_value: 'bharuch',

    talukas: [
      { taluka_name: 'Bharuch' },
      { taluka_name: 'Ankleshwar' },
      { taluka_name: 'Amod' },
      { taluka_name: 'Wagra' },
      { taluka_name: 'Hansot' },
      { taluka_name: 'Jambusar' },
      { taluka_name: 'Netrang' },
      { taluka_name: 'Walia' },
      { taluka_name: 'Jagadia' },
    ],
    number_of_talukas: 9,
  },
  {
    district_name: 'Bhavnagar',
    district_headquarters: 'Bhavnagar',
    district_value: 'bhavnagar',
    talukas: [
      { taluka_name: 'Bhavnagar' },
      { taluka_name: 'Ghogha' },
      { taluka_name: 'Mahuva' },
      { taluka_name: 'Gariadhar' },
      { taluka_name: 'Umrala' },
      { taluka_name: 'Jessar' },
      { taluka_name: 'Palitana' },
      { taluka_name: 'Shihor' },
      { taluka_name: 'Talaja' },
      { taluka_name: 'Valabhipur' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Ocean',
    district_headquarters: 'Lunavada',
    district_value: 'lunavada',

    talukas: [
      { taluka_name: 'Lunavada' },
      { taluka_name: 'Kadana' },
      { taluka_name: 'Khanpur' },
      { taluka_name: 'Balasinor' },
      { taluka_name: 'Virpur' },
      { taluka_name: 'Santrampur' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Mehsana',
    district_headquarters: 'Mehsana',
    district_value: 'mehsana',

    talukas: [
      { taluka_name: 'Mehsana' },
      { taluka_name: 'Kadi' },
      { taluka_name: 'Kheralu' },
      { taluka_name: 'Becharaji' },
      { taluka_name: 'Vadnagar' },
      { taluka_name: 'Visnagar' },
      { taluka_name: 'Vijapur' },
      { taluka_name: 'Unjha' },
      { taluka_name: 'Jotana' },
      { taluka_name: 'Satlasana' },
      { taluka_name: 'Gojariya' },
    ],
    number_of_talukas: 11,
  },
  {
    district_name: 'Morbi',
    district_headquarters: 'Morbi',
    district_value: 'morbi',

    talukas: [
      { taluka_name: 'Morbi' },
      { taluka_name: 'Maliya Miyana' },
      { taluka_name: 'Halwad' },
      { taluka_name: 'Wankaner' },
      { taluka_name: 'Tankara' },
    ],
    number_of_talukas: 5,
  },
  {
    district_name: 'Rajkot',
    district_headquarters: 'Rajkot',
    district_value: 'rajkot',
    talukas: [
      { taluka_name: 'Rajkot' },
      { taluka_name: 'Gondal' },
      { taluka_name: 'Dhoraji' },
      { taluka_name: 'Jamkandora' },
      { taluka_name: 'Jetpur' },
      { taluka_name: 'Jasdan' },
      { taluka_name: 'Kotdasangani' },
      { taluka_name: 'Paddhari' },
      { taluka_name: 'Upleta' },
      { taluka_name: 'Lodhika' },
      { taluka_name: 'Vichhiya' },
    ],
    number_of_talukas: 11,
  },
  {
    district_name: 'Vadodara',
    district_headquarters: 'Vadodara',
    district_value: 'vadodra',
    talukas: [
      { taluka_name: 'Vadodara' },
      { taluka_name: 'Karjan' },
      { taluka_name: 'Padra' },
      { taluka_name: 'Dabhoi' },
      { taluka_name: 'Savli' },
      { taluka_name: 'Shinor' },
      { taluka_name: 'Dassar' },
      { taluka_name: 'Vaghodia' },
    ],
    number_of_talukas: 8,
  },
  {
    district_name: 'Valsad',
    district_headquarters: 'Valsad',
    district_value: 'valsad',

    talukas: [
      { taluka_name: 'Valsad' },
      { taluka_name: 'Kaprada' },
      { taluka_name: 'Pardi' },
      { taluka_name: 'Vapi' },
      { taluka_name: 'Dharampur' },
      { taluka_name: 'Umragam' },
    ],
    number_of_talukas: 6,
  },
  {
    district_name: 'Sabarkantha',
    district_headquarters: 'Himmatnagar',
    district_value: 'himmatnagar',

    talukas: [
      { taluka_name: 'Himmatnagar' },
      { taluka_name: 'Khedbrahma' },
      { taluka_name: 'Prantij' },
      { taluka_name: 'Idar' },
      { taluka_name: 'Talod' },
      { taluka_name: 'Poshina' },
      { taluka_name: 'Vijaynagar' },
      { taluka_name: 'Wadali' },
    ],
    number_of_talukas: 8,
  },
  {
    district_name: 'Surat',
    district_headquarters: 'Surat',
    district_value: 'surat',
    talukas: [
      { taluka_name: 'Surat City' },
      { taluka_name: 'Kamarej' },
      { taluka_name: 'Bardoli' },
      { taluka_name: 'Mangrol' },
      { taluka_name: 'Mahuva' },
      { taluka_name: 'Olpad' },
      { taluka_name: 'Mandvi' },
      { taluka_name: 'Choryasi' },
      { taluka_name: 'Palsana' },
      { taluka_name: 'Umarpada' },
    ],
    number_of_talukas: 10,
  },
  {
    district_name: 'Surendranagar',
    district_headquarters: 'Surendranagar',
    district_value: 'surendranagar',
    talukas: [
      { taluka_name: 'Wadhwan' },
      { taluka_name: 'Patdi' },
      { taluka_name: 'Chotila' },
      { taluka_name: 'Dasada' },
      { taluka_name: 'Lakhtar' },
      { taluka_name: 'Dhrangadhra' },
      { taluka_name: 'Limbdi' },
      { taluka_name: 'Thangarh' },
      { taluka_name: 'Sayla' },
      { taluka_name: 'Chuda' },
    ],
    number_of_talukas: 10,
  },
];

export const dummy_users_data = [
  {
    first_name: 'Aarav',
    last_name: 'Agarwal',
    sub_cast: 'Agarwal',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '123, Harmony Street, Dhaneti, Abdasa, Gujarat, 370001',
    village: 'Dhaneti',
    vidhansabha: 'Abdasa',
    agenda_of_meeting: 'Pension Schemes',
    assembly_with: 'Aarav Singh',
    reference_if_any: 'Mr. Sharma (IAS)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Government Officer',
    status: 'Completed',
    date_and_time: '2023-01-01',
    details: '',
  },
  {
    first_name: 'Aanya',
    last_name: 'Ahuja',
    sub_cast: 'Ahir',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '456, Serenity Road, Mota Salaya, Mandvi (Kachchh), Gujarat, 370007',
    village: 'Mota Salaya',
    vidhansabha: 'Mandvi',
    agenda_of_meeting: 'Rural Development',
    assembly_with: 'Akshita Sharma',
    reference_if_any: 'Prof. Verma',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Journalist',
    status: 'Pending',
    date_and_time: '2023-02-15',
    details: 'Approval is pending',
  },
  {
    first_name: 'Abhinav',
    last_name: 'Bajaj',
    sub_cast: 'Ahluwalia',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '789, Tranquil Town, Khari, Bhuj, Gujarat, 370013',
    village: 'Khari',
    vidhansabha: 'Bhuj',
    agenda_of_meeting: 'Women Empowerment',
    assembly_with: 'Abhinav Patel',
    reference_if_any: 'Mrs. Yadav (MP)',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Citizen',
    status: 'In progress',
    date_and_time: '2023-03-22',
    details: '',
  },
  {
    first_name: 'Aditi',
    last_name: 'Balasubramanian',
    sub_cast: 'Aiyar',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '101, Peaceful Plaza, Khavda, Anjar, Gujarat, 370019',
    village: 'Khavda',
    vidhansabha: 'Anjar',
    agenda_of_meeting: 'Employment Opportunities',
    assembly_with: 'Aditi Desai',
    reference_if_any: 'Mr. Kapoor (IAS)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'Business Leader',
    status: 'Completed',
    date_and_time: '2023-04-10',
    details: '',
  },
  {
    first_name: 'Akash',
    last_name: 'Bansal',
    sub_cast: 'Anand',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '234, Harmony Heights, Adipur, Gandhidham (SC), Gujarat, 370025',
    village: 'Adipur',
    vidhansabha: 'Gandhidham',
    agenda_of_meeting: 'Rural Internet Connectivity',
    assembly_with: 'Aditya Verma',
    reference_if_any: 'Prof. Khan',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'NGO Representative',
    status: 'Pending',
    date_and_time: '2023-05-05',
    details: '',
  },
  {
    first_name: 'Alisha',
    last_name: 'Basu',
    sub_cast: 'Arora',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '567, River View Apartments, Chobari, Rapar, Gujarat, 370031',
    village: 'Chobari',
    vidhansabha: 'Rapar',
    agenda_of_meeting: 'Road and Transport',
    assembly_with: 'Aisha Gupta',
    reference_if_any: 'Mrs. Kapoor (MP)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Healthcare Professional',
    status: 'In progress',
    date_and_time: '2023-06-18',
    details: 'Approval is pending',
  },
  {
    first_name: 'Amrita',
    last_name: 'Bhat',
    sub_cast: 'Baghel',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '890, Sunshine Avenue, Santalpur, Vav, Gujarat, 370037',
    village: 'Santalpur',
    vidhansabha: 'Vav',
    agenda_of_meeting: 'Water Resource Management',
    assembly_with: 'Aryan Reddy',
    reference_if_any: 'Mr. Yadav (IAS)',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Environmental Activist',
    status: 'Completed',
    date_and_time: '2023-07-29',
    details: '',
  },
  {
    first_name: 'Aniket',
    last_name: 'Bhattacharya',
    sub_cast: 'Baidya',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '111, Garden Street, Derdi, Tharad, Gujarat, 370043',
    village: 'Derdi',
    vidhansabha: 'Tharad',
    agenda_of_meeting: 'Urban Planning and Development',
    assembly_with: 'Ananya Singh',
    reference_if_any: 'Prof. Joshi',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Youth Leader',
    status: 'Pending',
    date_and_time: '2023-08-11',
    details: '',
  },
  {
    first_name: 'Ananya',
    last_name: 'Chandra',
    sub_cast: 'Balija',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '222, Tranquil Terrace, Morvada, Dhanera, Gujarat, 370049',
    village: 'Morvada',
    vidhansabha: 'Dhanera',
    agenda_of_meeting: 'Public Safety and Security',
    assembly_with: 'Aniket Kapoor',
    reference_if_any: 'Mrs. Singh (MP)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'Senior Citizen',
    status: 'In progress',
    date_and_time: '2023-09-03',
    details: '',
  },
  {
    first_name: 'Anika',
    last_name: 'Chatterjee',
    sub_cast: 'Bania',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '333, Blissful Block, Korta, Danta (ST), Gujarat, 370055',
    village: 'Korta',
    vidhansabha: 'Danta',
    agenda_of_meeting: 'Technology and Innovation',
    assembly_with: 'Anjali Yadav',
    reference_if_any: 'Mr. Bansal (IAS)',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Technology Expert',
    status: 'Completed',
    date_and_time: '2023-10-14',
    details: '',
  },
  {
    first_name: 'Anil',
    last_name: 'Chawla',
    sub_cast: 'Banik',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '444, Green Valley, Bhildi, Vadgam (SC), Gujarat, 370061',
    village: 'Bhildi',
    vidhansabha: 'Vadgam',
    agenda_of_meeting: 'Disaster Management',
    assembly_with: 'Arjun Joshi',
    reference_if_any: 'Prof. Bansal',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Farmer',
    status: 'Pending',
    date_and_time: '2023-11-27',
    details: 'Approval is pending',
  },
  {
    first_name: 'Anisha',
    last_name: 'Choudhury',
    sub_cast: 'Barai',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '555, Riverside Road, Piludara, Palanpur, Gujarat, 370067',
    village: 'Piludara',
    vidhansabha: 'Palanpur',
    agenda_of_meeting: 'Science and Research Funding',
    assembly_with: 'Ashima Khanna',
    reference_if_any: 'Mrs. Khan (MP)',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Entrepreneur',
    status: 'In progress',
    date_and_time: '2023-12-09',
    details: '',
  },
  {
    first_name: 'Ankur',
    last_name: 'Das',
    sub_cast: 'Bari',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '666, Jubilant Junction, Galteshwar, Deesa, Gujarat, 370073',
    village: 'Galteshwar',
    vidhansabha: 'Deesa',
    agenda_of_meeting: 'National Highways Development',
    assembly_with: 'Ayush Tiwari',
    reference_if_any: 'Mr. Kapoor (IAS)',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Other',
    status: 'Completed',
    date_and_time: '2024-01-02',
    details: '',
  },
  {
    first_name: 'Anushka',
    last_name: 'Datta',
    sub_cast: 'Barua',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '777, Harmony Heights, Varad, Deodar, Gujarat, 370079',
    village: 'Varad',
    vidhansabha: 'Deodar',
    agenda_of_meeting: 'Clean Water Supply',
    assembly_with: 'Bhavya Sharma',
    reference_if_any: 'Prof. Yadav',
    no_of_visit: '4 Visits',
    visitor_category_list: 'Visitor Category',
    status: 'Pending',
    date_and_time: '2024-02-19',
    details: '',
  },
  {
    first_name: 'Arjun',
    last_name: 'Desai',
    sub_cast: 'Basak',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '888, Serenity Square, Kalyanpura, Kankrej, Gujarat, 370085',
    village: 'Kalyanpura',
    vidhansabha: 'Kankrej',
    agenda_of_meeting: 'Air Quality Improvement',
    assembly_with: 'Bhuvan Gupta',
    reference_if_any: 'Mrs. Joshi (MP)',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Government Officer',
    status: 'In progress',
    date_and_time: '2024-03-12',
    details: '',
  },
  {
    first_name: 'Aarushi',
    last_name: 'Dhawan',
    sub_cast: 'Batta',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '999, Divine Drive, Ismailpura, Radhanpur, Gujarat, 370091',
    village: 'Ismailpura',
    vidhansabha: 'Radhanpur',
    agenda_of_meeting: 'Industrial Corridor Development',
    assembly_with: 'Chetan Reddy',
    reference_if_any: 'Mr. Patel (IAS)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Journalist',
    status: 'Completed',
    date_and_time: '2024-04-05',
    details: '',
  },
  {
    first_name: 'Bhavesh',
    last_name: 'Dubey',
    sub_cast: 'Bawa',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '1111, Victory Villa, Bolundra, Chanasma, Gujarat, 370097',
    village: 'Bolundra',
    vidhansabha: 'Chanasma',
    agenda_of_meeting: 'Affordable Housing Schemes',
    assembly_with: 'Devika Verma',
    reference_if_any: 'Prof. Sharma',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Citizen',
    status: 'Pending',
    date_and_time: '2024-05-20',
    details: '',
  },
  {
    first_name: 'Bhoomi',
    last_name: 'Dutt',
    sub_cast: 'Bedi',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1222, Tranquil Terrace, Saroda, Patan, Gujarat, 370103',
    village: 'Saroda',
    vidhansabha: 'Patan',
    agenda_of_meeting: 'Cybersecurity Measures',
    assembly_with: 'Divya Kapoor',
    reference_if_any: 'Mrs. Yadav (MP)',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Business Leader',
    status: 'In progress',
    date_and_time: '2024-06-24',
    details: '',
  },
  {
    first_name: 'Chetan',
    last_name: 'Ganapathy',
    sub_cast: 'Belur',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1333, Dazzling Drive, Varana, Sidhpur, Gujarat, 370109',
    village: 'Varana',
    vidhansabha: 'Sidhpur',
    agenda_of_meeting: 'Affordable Healthcare',
    assembly_with: 'Eesha Yadav',
    reference_if_any: 'Mr. Verma (IAS)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'NGO Representative',
    status: 'Completed',
    date_and_time: '2024-07-06',
    details: '',
  },
  {
    first_name: 'Chhaya',
    last_name: 'Garg',
    sub_cast: 'Bera',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '1444, Peace Palace, Molipur, Kheralu, Gujarat, 370115',
    village: 'Molipur',
    vidhansabha: 'Kheralu',
    agenda_of_meeting: 'Cybercrime Prevention',
    assembly_with: 'Ekta Choudhury',
    reference_if_any: 'Prof. Joshi',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Healthcare Professional',
    status: 'Pending',
    date_and_time: '2024-08-30',
    details: '',
  },
  {
    first_name: 'Deepak',
    last_name: 'Ghosh',
    sub_cast: 'Bhadra',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1555, Karma Kottage, Jitodiya, Unjha, Gujarat, 370121',
    village: 'Jitodiya',
    vidhansabha: 'Unjha',
    agenda_of_meeting: 'Innovation Hubs',
    assembly_with: 'Farhan Siddiqui',
    reference_if_any: 'Mrs. Kapoor (MP)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Environmental Activist',
    status: 'In progress',
    date_and_time: '2024-09-17',
    details: '',
  },
  {
    first_name: 'Diya',
    last_name: 'Gill',
    sub_cast: 'Bhandari',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1666, Mellow Mansion, Vadasan, Visnagar, Gujarat, 370127',
    village: 'Vadasan',
    vidhansabha: 'Visnagar',
    agenda_of_meeting: 'Animal Welfare',
    assembly_with: 'Fatima Khan',
    reference_if_any: 'Mr. Bansal (IAS)',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Youth Leader',
    status: 'Completed',
    date_and_time: '2024-10-01',
    details: '',
  },
  {
    first_name: 'Divya',
    last_name: 'Goel',
    sub_cast: 'Bharadwaj',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    village: 'Chadasana',
    vidhansabha: 'Bechraji',
    agenda_of_meeting: 'Mental Health Awareness',
    assembly_with: 'Gaurav Sharma',
    reference_if_any: 'Prof. Yadav',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Senior Citizen',
    status: 'Pending',
    date_and_time: '2024-11-11',
    details: '',
  },
  {
    first_name: 'Esha',
    last_name: 'Goyal',
    sub_cast: 'Bhargava',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1888, Serendipity Society, Sardhav, Kadi (SC), Gujarat, 370139',
    village: 'Sardhav',
    vidhansabha: 'Kadi',
    agenda_of_meeting: 'Solid Waste Management',
    assembly_with: 'Gayatri Iyer',
    reference_if_any: 'Mrs. Sharma (MP)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'Technology Expert',
    status: 'In progress',
    date_and_time: '2024-12-29',
    details: '',
  },
  {
    first_name: 'Gaurav',
    last_name: 'Gupta',
    sub_cast: 'Bhat',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1999, Jubilant Junction, Modasar, Mahesana, Gujarat, 370145',
    village: 'Modasar',
    vidhansabha: 'Mahesana',
    agenda_of_meeting: 'Biofuel Production',
    assembly_with: 'Harsh Patel',
    reference_if_any: 'Mr. Sharma (IAS)',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Farmer',
    status: 'Completed',
    date_and_time: '2025-01-15',
    details: '',
  },
  {
    first_name: 'Gayatri',
    last_name: 'Iyer',
    sub_cast: 'Bhatti',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '2001, Keyhole Estate, Vanana, Vijapur, Gujarat, 370151',
    village: 'Vanana',
    vidhansabha: 'Vijapur',
    agenda_of_meeting: 'Environmental Conservation',
    assembly_with: 'Ishaan Kumar',
    reference_if_any: 'Prof. Verma',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Entrepreneur',
    status: 'Pending',
    date_and_time: '2025-02-28',
    details: '',
  },
  {
    first_name: 'Yashvi',
    last_name: 'Madan',
    sub_cast: 'Oberoi',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '444, Green Valley, Bhildi, Vadgam (SC), Gujarat, 370061',
    village: 'Bhildi',
    vidhansabha: 'Vadgam',
    agenda_of_meeting: 'Air Quality Improvement',
    assembly_with: 'Sudhir Kapoor',
    reference_if_any: 'Mrs. Joshi (MP)',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Government Officer',
    status: 'In progress',
    date_and_time: '2025-01-15',
    details: 'Approval is pending',
  },
  {
    first_name: 'Ziva',
    last_name: 'Maheshwari',
    sub_cast: 'Puri',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '555, Riverside Road, Piludara, Palanpur, Gujarat, 370067',
    village: 'Piludara',
    vidhansabha: 'Palanpur',
    agenda_of_meeting: 'Industrial Corridor Development',
    assembly_with: 'Swati Patel',
    reference_if_any: 'Mr. Patel (IAS)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Journalist',
    status: 'Completed',
    date_and_time: '2025-02-28',
    details: '',
  },
  {
    first_name: 'Advik',
    last_name: 'Malhotra',
    sub_cast: 'Sabharwal',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '666, Jubilant Junction, Galteshwar, Deesa, Gujarat, 370073',
    village: 'Galteshwar',
    vidhansabha: 'Deesa',
    agenda_of_meeting: 'Affordable Housing Schemes',
    assembly_with: 'Tanvi Verma',
    reference_if_any: 'Prof. Sharma',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Citizen',
    status: 'Pending',
    date_and_time: '2025-03-19',
    details: '',
  },
  {
    first_name: 'Amaira',
    last_name: 'Mehra',
    sub_cast: 'Sahni',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '777, Harmony Heights, Varad, Deodar, Gujarat, 370079',
    village: 'Varad',
    vidhansabha: 'Deodar',
    agenda_of_meeting: 'Cybersecurity Measures',
    assembly_with: 'Umesh Yadav',
    reference_if_any: 'Mrs. Yadav (MP)',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Business Leader',
    status: 'In progress',
    date_and_time: '2025-04-23',
    details: 'Approval is pending',
  },
  {
    first_name: 'Aneesh',
    last_name: 'Menon',
    sub_cast: 'Sethi',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '888, Serenity Square, Kalyanpura, Kankrej, Gujarat, 370085',
    village: 'Kalyanpura',
    vidhansabha: 'Kankrej',
    agenda_of_meeting: 'Affordable Healthcare',
    assembly_with: 'Varsha Sharma',
    reference_if_any: 'Mr. Verma (IAS)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'NGO Representative',
    status: 'Completed',
    date_and_time: '2025-05-11',
    details: '',
  },
  {
    first_name: 'Anshul',
    last_name: 'Mirza',
    sub_cast: 'Singh',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '999, Divine Drive, Ismailpura, Radhanpur, Gujarat, 370091',
    village: 'Ismailpura',
    vidhansabha: 'Radhanpur',
    agenda_of_meeting: 'Cybercrime Prevention',
    assembly_with: 'Vicky Patel',
    reference_if_any: 'Prof. Joshi',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Healthcare Professional',
    status: 'Pending',
    date_and_time: '2025-06-16',
    details: '',
  },
  {
    first_name: 'Aryansh',
    last_name: 'Mittal',
    sub_cast: 'Sood',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1111, Victory Villa, Bolundra, Chanasma, Gujarat, 370097',
    village: 'Bolundra',
    vidhansabha: 'Chanasma',
    agenda_of_meeting: 'Innovation Hubs',
    assembly_with: 'Yashika Kapoor',
    reference_if_any: 'Mrs. Kapoor (MP)',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Environmental Activist',
    status: 'In progress',
    date_and_time: '2025-07-27',
    details: '',
  },
  {
    first_name: 'Avishi',
    last_name: 'Mukhopadhyay',
    sub_cast: 'Talwar',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1222, Tranquil Terrace, Saroda, Patan, Gujarat, 370103',
    village: 'Saroda',
    vidhansabha: 'Patan',
    agenda_of_meeting: 'Animal Welfare',
    assembly_with: 'Aditi Yadav',
    reference_if_any: 'Mr. Bansal (IAS)',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Youth Leader',
    status: 'Completed',
    date_and_time: '2025-08-08',
    details: '',
  },
  {
    first_name: 'Barkha',
    last_name: 'Nadkarni',
    sub_cast: 'Vaid',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '1333, Dazzling Drive, Varana, Sidhpur, Gujarat, 370109',
    village: 'Varana',
    vidhansabha: 'Sidhpur',
    agenda_of_meeting: 'Mental Health Awareness',
    assembly_with: 'Ajay Sharma',
    reference_if_any: 'Prof. Yadav',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Senior Citizen',
    status: 'Pending',
    date_and_time: '2025-09-22',
    details: '',
  },
  {
    first_name: 'Chandni',
    last_name: 'Narayan',
    sub_cast: 'Verma',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1444, Peace Palace, Molipur, Kheralu, Gujarat, 370115',
    village: 'Molipur',
    vidhansabha: 'Kheralu',
    agenda_of_meeting: 'Solid Waste Management',
    assembly_with: 'Alisha Patel',
    reference_if_any: 'Mrs. Sharma (MP)',
    no_of_visit: '4 Visits',
    visitor_category_list: 'Technology Expert',
    status: 'In progress',
    date_and_time: '2025-10-09',
    details: '',
  },
  {
    first_name: 'Divit',
    last_name: 'Nayar',
    sub_cast: 'Wahi',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1555, Karma Kottage, Jitodiya, Unjha, Gujarat, 370121',
    village: 'Jitodiya',
    vidhansabha: 'Unjha',
    agenda_of_meeting: 'Biofuel Production',
    assembly_with: 'Amrit Verma',
    reference_if_any: 'Mr. Sharma (IAS)',
    no_of_visit: '5+ Visits',
    visitor_category_list: 'Farmer',
    status: 'Completed',
    date_and_time: '2025-11-02',
    details: '',
  },
  {
    first_name: 'Ekisha',
    last_name: 'Oberoi',
    sub_cast: 'Batra',
    voter_id: 'ABC123456',
    mobile_no: 8765432109,
    address: '1666, Mellow Mansion, Vadasan, Visnagar, Gujarat, 370127',
    village: 'Vadasan',
    vidhansabha: 'Visnagar',
    agenda_of_meeting: 'Environmental Conservation',
    assembly_with: 'Anand Joshi',
    reference_if_any: 'Prof. Verma',
    no_of_visit: '1 Visit',
    visitor_category_list: 'Entrepreneur',
    status: 'Pending',
    date_and_time: '2023-01-01',
    details: '',
  },
  {
    first_name: 'Farhan',
    last_name: 'Pande',
    sub_cast: 'Behl',
    voter_id: 'ABC123456',
    mobile_no: 7654321098,
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    village: 'Chadasana',
    vidhansabha: 'Bechraji',
    agenda_of_meeting: 'Rural Sanitation',
    assembly_with: 'Anjali Yadav',
    reference_if_any: 'Mrs. Yadav (MP)',
    no_of_visit: '2 Visits',
    visitor_category_list: 'Other',
    status: 'In progress',
    date_and_time: '2023-02-15',
    details: 'Approval is pending',
  },
  {
    first_name: 'Gargee',
    last_name: 'Panicker',
    sub_cast: 'Bhalla',
    voter_id: 'ABC123456',
    mobile_no: 9876543210,
    address: '1888, Serendipity Society, Sardhav, Kadi (SC), Gujarat, 370139',
    village: 'Sardhav',
    vidhansabha: 'Kadi',
    agenda_of_meeting: 'Rural Connectivity',
    assembly_with: 'Anmol Kapoor',
    reference_if_any: 'Mr. Kapoor (IAS)',
    no_of_visit: '3 Visits',
    visitor_category_list: 'Visitor Category',
    status: 'Completed',
    date_and_time: '2023-03-22',
    details: '',
  },
];

export const userPersonalData = [
  {
    sr_no: 1,
    jilla_name: 'Vadodra',
    taluka_name: 'Savli',
    ayam_name: 'ધાર્મિક (મઠ મંદિર)',
    date: '01/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Thakor Samaj, Kanodar.',
    sanstha_ma_sampark_karele_nam: 'Babarji Thakor/ Vanraj  Thakor',
    sanstha_ma_sampark_karele_hodo: 'સમાજના પ્રમુખ',
    sanstha_ma_sampark_karele_contact_number: 999999999,
  },
  {
    sr_no: 2,
    jilla_name: 'Vadodra',
    taluka_name: 'Savli',
    ayam_name: 'ધાર્મિક (મઠ મંદિર)',
    date: '02/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Lions club surendranagar main',
    sanstha_ma_sampark_karele_nam: 'Mukesh vasani',
    sanstha_ma_sampark_karele_hodo: 'Samaj na Pramukh',
    sanstha_ma_sampark_karele_contact_number: 9999999999,
  },
  {
    sr_no: 3,
    jilla_name: 'Vadodra',
    taluka_name: 'Savli',
    ayam_name: 'ધાર્મિક (મઠ મંદિર)',
    date: '02/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Prajapati vadi',
    sanstha_ma_sampark_karele_nam: 'Himat bhalgama',
    sanstha_ma_sampark_karele_hodo: 'Samitina Sabhya',
    sanstha_ma_sampark_karele_contact_number: 999999999,
  },
  {
    sr_no: 4,
    jilla_name: 'Vadodra',
    taluka_name: 'Savli',
    ayam_name: 'ધાર્મિક (મઠ મંદિર)',
    date: '02/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Lions club surendranagar main',
    sanstha_ma_sampark_karele_nam: 'Mukesh vasani',
    sanstha_ma_sampark_karele_hodo: 'Samaj na Pramukh',
    sanstha_ma_sampark_karele_contact_number: 9999999999,
  },
  {
    sr_no: 5,
    jilla_name: 'Vadodra',
    taluka_name: 'Savli',
    ayam_name: 'ધાર્મિક (મઠ મંદિર)',
    date: '02/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Prajapati vadi',
    sanstha_ma_sampark_karele_nam: 'Himat bhalgama',
    sanstha_ma_sampark_karele_hodo: 'Samitina Sabhya',
    sanstha_ma_sampark_karele_contact_number: 999999999,
  },
  {
    sr_no: 6,
    jilla_name: 'Surat',
    taluka_name: 'Bardoli',
    ayam_name: 'સામાજિક',
    date: '03/03/2024',
    sanstha_no_prakar: 'Trust',
    sanstha_nu_nam: 'Shri Sai Charitable Trust',
    sanstha_ma_sampark_karele_nam: 'Nitin Patel',
    sanstha_ma_sampark_karele_hodo: 'Chairman',
    sanstha_ma_sampark_karele_contact_number: 8888888888,
  },
  {
    sr_no: 7,
    jilla_name: 'Surat',
    taluka_name: 'Bardoli',
    ayam_name: 'સામાજિક',
    date: '04/03/2024',
    sanstha_no_prakar: 'Trust',
    sanstha_nu_nam: 'Vatsalya Foundation',
    sanstha_ma_sampark_karele_nam: 'Rajesh Shah',
    sanstha_ma_sampark_karele_hodo: 'Samaj na Pramukh',
    sanstha_ma_sampark_karele_contact_number: 7777777777,
  },
  {
    sr_no: 8,
    jilla_name: 'Surat',
    taluka_name: 'Bardoli',
    ayam_name: 'સામાજિક',
    date: '05/03/2024',
    sanstha_no_prakar: 'Trust',
    sanstha_nu_nam: 'Bardoli Sarvajanik Charitable Trust',
    sanstha_ma_sampark_karele_nam: 'Anand Patel',
    sanstha_ma_sampark_karele_hodo: 'Secretary',
    sanstha_ma_sampark_karele_contact_number: 6666666666,
  },
  {
    sr_no: 9,
    jilla_name: 'Ahmedabad',
    taluka_name: 'Daskroi',
    ayam_name: 'સામાજિક',
    date: '06/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Gujarat Mitra Mandal',
    sanstha_ma_sampark_karele_nam: 'Ramesh Patel',
    sanstha_ma_sampark_karele_hodo: 'Founder',
    sanstha_ma_sampark_karele_contact_number: 5555555555,
  },
  {
    sr_no: 10,
    jilla_name: 'Ahmedabad',
    taluka_name: 'Daskroi',
    ayam_name: 'સામાજિક',
    date: '07/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Helping Hands Foundation',
    sanstha_ma_sampark_karele_nam: 'Sneha Shah',
    sanstha_ma_sampark_karele_hodo: 'Coordinator',
    sanstha_ma_sampark_karele_contact_number: 4444444444,
  },
  {
    sr_no: 11,
    jilla_name: 'Ahmedabad',
    taluka_name: 'Daskroi',
    ayam_name: 'સામાજિક',
    date: '08/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Youth Welfare Foundation',
    sanstha_ma_sampark_karele_nam: 'Kiran Patel',
    sanstha_ma_sampark_karele_hodo: 'Director',
    sanstha_ma_sampark_karele_contact_number: 3333333333,
  },
  {
    sr_no: 12,
    jilla_name: 'Ahmedabad',
    taluka_name: 'Daskroi',
    ayam_name: 'સામાજિક',
    date: '09/03/2024',
    sanstha_no_prakar: 'Trust',
    sanstha_nu_nam: 'Sharda Education Trust',
    sanstha_ma_sampark_karele_nam: 'Neha Shah',
    sanstha_ma_sampark_karele_hodo: 'Chairman',
    sanstha_ma_sampark_karele_contact_number: 2222222222,
  },
  {
    sr_no: 13,
    jilla_name: 'Rajkot',
    taluka_name: 'Gondal',
    ayam_name: 'સામાજિક',
    date: '10/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Rajkot Social Service Foundation',
    sanstha_ma_sampark_karele_nam: 'Rahul Patel',
    sanstha_ma_sampark_karele_hodo: 'Samaj na Pramukh',
    sanstha_ma_sampark_karele_contact_number: 1111111111,
  },
  {
    sr_no: 14,
    jilla_name: 'Rajkot',
    taluka_name: 'Gondal',
    ayam_name: 'સામાજિક',
    date: '11/03/2024',
    sanstha_no_prakar: 'Trust',
    sanstha_nu_nam: 'Gondal Charitable Trust',
    sanstha_ma_sampark_karele_nam: 'Mohan Patel',
    sanstha_ma_sampark_karele_hodo: 'Secretary',
    sanstha_ma_sampark_karele_contact_number: 9999999999,
  },
  {
    sr_no: 15,
    jilla_name: 'Rajkot',
    taluka_name: 'Gondal',
    ayam_name: 'સામાજિક',
    date: '12/03/2024',
    sanstha_no_prakar: 'NGO',
    sanstha_nu_nam: 'Gondal Youth Foundation',
    sanstha_ma_sampark_karele_nam: 'Divya Patel',
    sanstha_ma_sampark_karele_hodo: 'Chairman',
    sanstha_ma_sampark_karele_contact_number: 8888888888,
  },
];

export const vibhag_search_option = [
  { value: 'vibhag_data_1', label: 'Vibhag Data 1', guj_label: 'વિભાગ ના ડેટા 1' },
  { value: 'vibhag_data_2', label: 'Vibhag Data 2', guj_label: 'વિભાગ ના ડેટા 2' },
  { value: 'vibhag_data_3', label: 'Vibhag Data 3', guj_label: 'વિભાગ ના ડેટા 3' },
];

export const jilla_search_option = [
  { value: 'jilla_data_1', label: 'District Data 1', guj_label: 'જિલ્લા ના ડેટા 1' },
  { value: 'jilla_data_2', label: 'District Data 2', guj_label: 'જિલ્લા ના ડેટા 2' },
  { value: 'jilla_data_3', label: 'District Data 3', guj_label: 'જિલ્લા ના ડેટા 3' },
];

export const taluka_search_option = [
  { value: 'taluka_data_1', label: 'Taluka Data 1', guj_label: 'તાલુકા ના ડેટા 1' },
  { value: 'taluka_data_2', label: 'Taluka Data 2', guj_label: 'તાલુકા ના ડેટા 2' },
  { value: 'taluka_data_3', label: 'Taluka Data 3', guj_label: 'તાલુકા ના ડેટા 3' },
];

export const dummy_history_data = [
  {
    date: '01/01/2023',
    details: '',
    name_of_the_organization: 'Thakor Samaj, Nadiad.',
    guj_name_of_the_organization: 'ઠાકોર સમાજ, નડિયાદ',
    type_of_organization: 'NGO',
    guj_type_of_organization: 'NGO',
    designation_of_contact_person_organization: 'Samaj na Pramukh',
    guj_designation_of_contact_person_organization: 'સમાજ ના પ્રમુખ',
    name_of_contact_person_organization: 'Vanraj  Thakor',
    guj_name_of_contact_person_organization: 'વનરાજ ઠાકોર',
    mobile_no_of_contact_person_organization: '999999999',
    email_id: 'email@gmail.com',
    contact_no: '999999999',
    taluka_name: 'Nadiad',
    guj_taluka_name: 'નડિયાદ',
    value_unique_key: '001',
  },
  {
    date: '02/01/2023',
    name_of_the_organization: 'Lions club Amreli main',
    guj_name_of_the_organization: 'લાયન્સ ક્લબ અમરેલી મુખ્ય',
    type_of_organization: 'NGO',
    guj_type_of_organization: 'NGO',
    designation_of_contact_person_organization: 'Samaj na Pramukh',
    guj_designation_of_contact_person_organization: 'સમાજ ના પ્રમુખ',
    name_of_contact_person_organization: 'Mukesh Vasani',
    guj_name_of_contact_person_organization: 'મુકેશ વસાણી',
    mobile_no_of_contact_person_organization: '999999999',
    email_id: 'email@gmail.com',
    contact_no: '999999999',
    taluka_name: 'Amreli',
    guj_taluka_name: 'અમરેલી',
    value_unique_key: '002',
  },
  {
    date: '03/01/2023',
    name_of_the_organization: 'Prajapati vadi',
    guj_name_of_the_organization: 'પ્રજાપતિ વાડી',
    type_of_organization: 'NGO',
    guj_type_of_organization: 'NGO',
    designation_of_contact_person_organization: 'Samitina Sabhya',
    guj_designation_of_contact_person_organization: 'સમિતિના સભ્ય',
    name_of_contact_person_organization: 'Himat Bhalgama',
    guj_name_of_contact_person_organization: 'હિમત ભલગામા',
    mobile_no_of_contact_person_organization: '999999999',
    email_id: 'email@gmail.com',
    contact_no: '999999999',
    taluka_name: 'Mahesana',
    guj_taluka_name: 'મહેસાણા',
    value_unique_key: '003',
  },
  {
    date: '04/01/2023',
    name_of_the_organization: 'Patel vadi',
    guj_name_of_the_organization: 'પટેલ વાડી',
    type_of_organization: 'NGO',
    guj_type_of_organization: 'NGO',
    designation_of_contact_person_organization: 'Sabhya',
    guj_designation_of_contact_person_organization: 'સભ્ય',
    name_of_contact_person_organization: 'Anil Patel',
    guj_name_of_contact_person_organization: 'અનિલ પટેલ',
    mobile_no_of_contact_person_organization: '999999999',
    email_id: 'email@gmail.com',
    contact_no: '999999999',
    taluka_name: 'Bhuj',
    guj_taluka_name: 'ભુજ',
    value_unique_key: '004',
  },
  {
    date: '02/01/2023',
    name_of_the_organization: 'Thakor Samaj, Rajkot',
    guj_name_of_the_organization: 'ઠાકોર સમાજ, રાજકોટ',
    type_of_organization: 'NGO',
    guj_type_of_organization: 'NGO',
    designation_of_contact_person_organization: 'Samitina Sabhya',
    guj_designation_of_contact_person_organization: 'સમિતિના સભ્ય',
    name_of_contact_person_organization: 'Mukesh Vasani',
    guj_name_of_contact_person_organization: 'મુકેશ વસાણી',
    mobile_no_of_contact_person_organization: '999999999',
    email_id: 'email@gmail.com',
    contact_no: '999999999',
    taluka_name: 'Rajkot',
    guj_taluka_name: 'Rajkot',
    value_unique_key: '005',
  },
];

export const EventsData = [
  {
    department_name: 'Conserve Forests & Protect WildLife',
    guj_department_name: 'વન અને વન્યજીવન સંરક્ષણ',
    department_value: 'van_ane_vanyajivan_sanrakshan',
    program_name: 'Prakurti Vandan',
    guj_program_name: 'પ્રકૃતિ વંદના',
    start_event_date: '07/03/2024',
    end_event_date: '08/09/2024',
    total_registered_users: 150,
    total_participant_users: 100,
    total_numbers_of_sanstha: 60,
    total_numbers_of_individuals: 30,
    report_generated: true,
    id: '001',
    value: 'prakruti_vandan',
  },
  {
    department_name: 'Preserve Ecology',
    guj_department_name: 'જીવસૃષ્ટિ સંતુલન',
    department_value: 'jivasrsti_santulan',
    program_name: 'Matru - Pitru Vandan',
    guj_program_name: 'માતૃ - પિતૃ વંદન',
    start_event_date: '01/30/2024',
    end_event_date: '02/08/2024',
    total_registered_users: 80,
    total_participant_users: 10,
    total_numbers_of_sanstha: 40,
    total_numbers_of_individuals: 100,
    report_generated: false,
    id: '002',
    value: 'matru_pitru_vandan',
  },
  {
    department_name: 'Sustain Environment',
    guj_department_name: 'પર્યાવરણ સંરક્ષણ',
    department_value: 'paryavaran_sanraksan',
    program_name: 'Aacharya Vandan',
    guj_program_name: 'આચાર્ય વંદન',
    value: 'aacharya_vandan',
    start_event_date: '05/13/2024',
    end_event_date: '05/20/2024',
    total_registered_users: 120,
    total_participant_users: 70,
    total_numbers_of_sanstha: 40,
    total_numbers_of_individuals: 90,
    report_generated: true,
    id: '003',
  },
  {
    department_name: 'Preserve Ecology',
    guj_department_name: 'જીવસૃષ્ટિ સંતુલન',
    department_value: 'jivasrsti_santulan',
    program_name: 'Paramveer Vandan',
    guj_program_name: 'પરમવીર વંદન',
    value: 'paramveer_vandan',
    start_event_date: '08/08/2024',
    end_event_date: '08/09/2024',
    total_registered_users: 80,
    total_participant_users: 20,
    total_numbers_of_sanstha: 10,
    total_numbers_of_individuals: 10,
    report_generated: true,
    id: '004',
  },
  {
    department_name: 'Inculcate Family & Human Values',
    guj_department_name: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    department_value: 'parivarik_tatha_manaviy_mulyo_nu_jatan',
    program_name: 'Bharat Mata Vandan',
    guj_program_name: 'ભારત માતા વંદન',
    value: 'bharat_mata_vandan',
    start_event_date: '10/08/2023',
    end_event_date: '11/01/2023',
    total_registered_users: 1500,
    total_participant_users: 200,
    total_numbers_of_sanstha: 120,
    total_numbers_of_individuals: 800,
    report_generated: false,
    id: '005',
  },
  {
    department_name: 'Foster Women`s Honour',
    guj_department_name: 'નારી સન્માન અને સંરક્ષણ',
    department_value: 'nari_sanman_ane_sanraksan',
    program_name: 'Aacharya Vandan',
    guj_program_name: 'આચાર્ય વંદન',
    value: 'aacharya_vandan',
    start_event_date: '03/10/2024',
    end_event_date: '04/01/2024',
    total_registered_users: 234,
    total_participant_users: 100,
    total_numbers_of_sanstha: 20,
    total_numbers_of_individuals: 345,
    report_generated: true,
    id: '006',
  },
  {
    department_name: 'Conserve Forests & Protect WildLife',
    guj_department_name: 'વન અને વન્યજીવન સંરક્ષણ',
    department_value: 'van_ane_vanyajivan_sanrakshan',
    program_name: 'Prakruti Vandan',
    guj_program_name: 'પ્રકૃતિ વંદન',
    value: 'prakruti_vandan',
    start_event_date: '06/02/2024',
    end_event_date: '06/09/2024',
    total_registered_users: 678,
    total_participant_users: 22,
    total_numbers_of_sanstha: 123,
    total_numbers_of_individuals: 230,
    report_generated: true,
    id: '007',
  },
  {
    department_name: 'Sustain Environment',
    guj_department_name: 'પર્યાવરણ સંરક્ષણ',
    department_value: 'paryavaran_sanraksan',
    program_name: 'Bharat Mata Vandan',
    guj_program_name: 'ભારત માતા વંદન',
    value: 'bharat_mata_vandan',
    start_event_date: '10/08/2023',
    end_event_date: '11/01/2023',
    total_registered_users: 1500,
    total_participant_users: 200,
    total_numbers_of_sanstha: 120,
    total_numbers_of_individuals: 800,
    report_generated: true,
    id: '008',
  },
];

// Programme Registration
export const theme_option = [
  // 1
  {
    label: 'Conserve Forests & Protect WildLife',
    guj_label: 'વન અને વન્યજીવન સંરક્ષણ',
    value: 'van_ane_vanyajivan_sanrakshan',
    option_value: '1',
  },
  {
    label: 'Preserve Ecology',
    guj_label: 'જીવસૃષ્ટિ સંતુલન',
    value: 'jivasrsti_santulan',
    option_value: '1',
  },
  {
    label: 'Sustain Environment',
    guj_label: 'પર્યાવરણ સંરક્ષણ',
    value: 'paryavaran_sanraksan',
    option_value: '1',
  },
  // 1
  // 2
  {
    label: 'Inculcate Family & Human Values',
    guj_label: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    value: 'parivarik_tatha_manaviy_mulyo_nu_jatan',
    option_value: '2',
  },
  // 2
  // 3
  {
    label: 'Foster Women`s Honour',
    guj_label: 'નારી સન્માન અને સંરક્ષણ',
    value: 'nari_sanman_ane_sanraksan',
    option_value: '3',
  },
  // 3
  // 4
  {
    label: 'Instill Patriotism',
    guj_label: 'રાષ્ટ્રભક્તિ જાગરણ',
    value: 'rastrabhakti_jagrn',
    option_value: '4',
  },
  // 4
];
// programme option
export const programme_option = [
  // 1
  {
    label: 'Prakruti Vandan',
    guj_label: 'પ્રકૃતિ વંદન',
    value: 'prakruti_vandan',
    option_value: '1',
  },
  // 1
  // 2
  {
    label: 'Matru - Pitru Vandan',
    guj_label: 'માતૃ - પિતૃ વંદન',
    value: 'matru_pitru_vandan',
    option_value: '2',
  },
  {
    label: 'Aacharya Vandan',
    guj_label: 'આચાર્ય વંદન',
    value: 'aacharya_vandan',
    option_value: '2',
  },
  // 2
  // 3
  {
    label: 'Kanya Vandan',
    guj_label: 'કન્યા વંદન',
    value: 'kanya_vandan',
    option_value: '3',
  },
  // 3
  // 4
  {
    label: 'Bharat Mata Vandan',
    guj_label: 'ભારત માતા વંદન',
    value: 'bharat_mata_vandan',
    option_value: '4',
  },
  {
    label: 'Paramveer Vandan',
    guj_label: 'પરમવીર વંદન',
    value: 'paramveer_vandan',
    option_value: '4',
  },
  // 4
];

// organization options

export const organization_option = [
  {
    value: 'kuvarba_kanya_vidyalay_palanpur',
    guj_label: 'Kuvarba Kanya Vidyalay, Palanpur',
    label: 'Kuvarba Kanya Vidyalay, Palanpur',
  },
  {
    value: 'skm_highschool_kanodar',
    guj_label: 'SKM Highschool, Kanodar',
    label: 'SKM Highschool, Kanodar',
  },
  {
    value: 'sadhi_ma_mandir_kanodar',
    guj_label: 'Sadhi ma Mandir. Kanodar',
    label: 'Sadhi ma Mandir. Kanodar',
  },
  {
    value: 'kanodar_anupam_school_no.3',
    guj_label: 'KANODAR Anupam School No.3',
    label: 'KANODAR Anupam School No.3',
  },
  {
    value: 'r_k_gharshala_vinay_mandir_bhavnagar',
    guj_label: 'R.K. Dharamshala Vinay Mandir, Bhavnagar',
    label: 'R.K. Dharamshala Vinay Mandir, Bhavnagar',
  },
  {
    value: 'shantilal_shah_highschool_bhavnagar',
    guj_label: 'શાંતિલાલ શાહ હાઇસ્કુલ, ભાવનગર',
    label: 'Shantilal Shah Highschool, Bhavnagar',
  },
  {
    value: 'kanya_vinay_mandir_sanskar_tirth_ajol',
    guj_label: 'કન્યા વિનય મંદિર સંસ્કારતીર્થ-આજોલ',
    label: 'Kanya Vinay Mandir Sanskar Tirth-Ajol',
  },
  { value: 'vatrakgadh_gam', guj_label: 'Vatrakgadh Gam', label: 'Vatrakgadh Gam' },
  {
    value: 'naliya_primary_school',
    guj_label: 'Naliya Primary School',
    label: 'Naliya Primary School',
  },
  {
    value: 'shri_rajpar_pra_school',
    guj_label: 'શ્રી રાજપર પ્રા શાળા',
    label: 'Shree Rajpar Pra School',
  },
  {
    value: 'chharad_primary_school',
    guj_label: 'છારદ પે.સે. શાળા',
    label: 'Chharad Primary School',
  },
  { value: 'bhms_college_limdi', guj_label: 'BHMS COLLEGE LIMBDI', label: 'BHMS COLLEGE LIMBDI' },
  {
    value: 'shri_swaminarayan_sanskardham_gurukul',
    guj_label: 'શ્રી સ્વામિનારાયણ સંસ્કારધામ ગુરુકુળ',
    label: 'Shri Swaminarayan Sanskardham Gurukul',
  },
];

export const user_management_data = [
  {
    id: '1',
    value_unique_key: '001',
    person_name: 'Aarav Agarwal',
    guj_person_name: 'આરવ અગ્રવાલ',
    contact_person_name: 'Vishanu Bhatt',
    guj_contact_person_name: 'વિષ્ણુ ભટ્ટ',
    vibhag_name: 'Mahesana',
    guj_vibhag_name: 'મહેસાણા',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'aarav@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Dharmik Institutions',
    guj_type_of_sanstha: 'ધાર્મિક (મઠ મંદિર)',
    type_of_sanstha_value: 'dharmik',
    sanstha_name: 'Kuvarba Kanya Vidyalay',
    guj_sanstha_name: 'કુવરબા કન્યા વિદ્યાલય',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'NGO',
    guj_organization_type: 'NGO',
  },
  {
    id: '2',
    value_unique_key: '002',
    person_name: 'Kuldip Nagor',
    guj_person_name: 'કુલદિપ નાગોર',
    contact_person_name: 'Aanya Ahuja',
    guj_contact_person_name: 'અનન્યા આહુજા',
    vibhag_name: 'Navsari',
    guj_vibhag_name: 'નવસારી',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'aanya@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Educational Institutions',
    guj_type_of_sanstha: 'શૈક્ષણિક સંસ્થા',
    type_of_sanstha_value: 'shikshan_sanstha_sampark',
    sanstha_name: 'SKM Highschool',
    guj_sanstha_name: 'SKM હાઈસ્કૂલ',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'Dharmik Institutions',
    guj_organization_type: 'ધાર્મિક (મઠ મંદિર)',
  },
  {
    id: '3',
    value_unique_key: '003',
    person_name: 'Barkha Nadkarni',
    guj_person_name: 'બરખા નાડકર્ણી',
    contact_person_name: 'Dixit Varma',
    guj_contact_person_name: 'દીક્ષિત વર્મા',
    vibhag_name: 'Surat',
    guj_vibhag_name: 'સુરત',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'barkha@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Socialist (NGOs)',
    guj_type_of_sanstha: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
    type_of_sanstha_value: 'samajik_sanstha_sampark',
    sanstha_name: 'Sadhi ma Mandir',
    guj_sanstha_name: 'સધી મા મંદિર',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'NGO',
    guj_organization_type: 'NGO',
  },
  {
    id: '4',
    value_unique_key: '004',
    person_name: 'Gargee Panicker',
    guj_person_name: 'ગાર્ગી પનીકર',
    contact_person_name: 'Krishana Rathod',
    guj_contact_person_name: 'ક્રિષ્ના રાઠોડ',
    vibhag_name: 'Vadodra',
    guj_vibhag_name: 'વડોદરા',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'gargee@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Youth Wings',
    guj_type_of_sanstha: 'યુવા શક્તિ',
    type_of_sanstha_value: 'yuva_shakti',
    sanstha_name: 'KANODAR Anupam School No.3',
    guj_sanstha_name: 'કાણોદર અનુપમ શાળા નં.3',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'Dharmik Institutions',
    guj_organization_type: 'ધાર્મિક (મઠ મંદિર)',
  },
  {
    id: '5',
    value_unique_key: '005',
    person_name: 'Valmik Krishana',
    guj_person_name: 'વાલ્મીક કૃષ્ણ',
    contact_person_name: 'Divit Nayar',
    guj_contact_person_name: 'દિવિત નાયર',
    vibhag_name: 'Nadiyad',
    guj_vibhag_name: 'નડિયાદ',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'divit@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Female Wings',
    guj_type_of_sanstha: 'નારી શક્તિ',
    type_of_sanstha_value: 'nari_shakti',
    sanstha_name: 'Shantilal Shah High School',
    guj_sanstha_name: 'શાંતિલાલ શાહ હાઇસ્કુલ',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'NGO',
    guj_organization_type: 'NGO',
  },
  {
    id: '6',
    value_unique_key: '006',
    person_name: 'Chandni Narayan',
    guj_person_name: 'ચાંદની નારાયણ',
    contact_person_name: 'Avani Altal',
    guj_contact_person_name: 'અવની અલ્તાલ',
    vibhag_name: 'Karnavati - East (ahmedabad)',
    guj_vibhag_name: 'કર્ણાવતી - પૂર્વ (અમદાવાદ)',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'chandni@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Family Organizations',
    guj_type_of_sanstha: 'પરિવાર ક્ષેત્ર',
    type_of_sanstha_value: 'parivar_kshetra',
    sanstha_name: 'Kanya Vinaya Mandir Sanskarteertha-Ajol',
    guj_sanstha_name: 'કન્યા વિનય મંદિર સંસ્કારતીર્થ-આજોલ',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'Dharmik Institutions',
    guj_organization_type: 'ધાર્મિક (મઠ મંદિર)',
  },
  {
    id: '7',
    value_unique_key: '007',
    person_name: 'Avishi Mukhopadhyay',
    guj_person_name: 'અવશી મુખોપાધ્યાય',
    contact_person_name: 'Gaurav Gupta',
    guj_contact_person_name: 'ગૌરવ ગુપ્તા',
    vibhag_name: 'Karnavati - West (ahmedabad)',
    guj_vibhag_name: 'કર્ણાવતી - પશ્ચિમ (અમદાવાદ)',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'avishi@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Corporate Houses',
    guj_type_of_sanstha: 'કોર્પોરેટ સંપર્ક',
    type_of_sanstha_value: 'corporate_sampark',
    sanstha_name: 'Vatrakgadh Gam',
    guj_sanstha_name: 'વત્રકગઢ ગામ',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'Educational',
    guj_organization_type: 'શૈક્ષણિક',
  },
  {
    id: '8',
    value_unique_key: '008',
    person_name: 'Anshul Mirza',
    guj_person_name: 'અંશુલ મિર્ઝા',
    contact_person_name: 'Ziva Maheshwari',
    guj_contact_person_name: 'ઝીવા મહેશ્વરી',
    vibhag_name: 'Mahesana',
    guj_vibhag_name: 'મહેસાણા',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'anshul@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Media / Social Media',
    guj_type_of_sanstha: 'પ્રચાર - પ્રસાર',
    type_of_sanstha_value: 'prachar_jaherat',
    sanstha_name: 'Naliya Primary School',
    guj_sanstha_name: 'નલિયા પ્રાથમિક શાળા',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'NGO',
    guj_organization_type: 'NGO',
  },
  {
    id: '9',
    value_unique_key: '009',
    person_name: 'Gayatri Iyer',
    guj_person_name: 'ગાયત્રી અય્યર',
    contact_person_name: 'Advik Malhotra',
    guj_contact_person_name: 'અદ્વિક મલ્હોત્રા',
    vibhag_name: 'Gandhinagar',
    guj_vibhag_name: 'ગાંધીનગર',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'gayatri@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Office',
    guj_type_of_sanstha: 'કાર્યાલય',
    type_of_sanstha_value: 'karyalaya',
    sanstha_name: 'Darshan Vidhyalay',
    guj_sanstha_name: 'દર્શન વિદ્યાલય',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'Educational',
    guj_organization_type: 'શૈક્ષણિક',
  },
  {
    id: '10',
    value_unique_key: '1000',
    person_name: 'Yashvi Madan',
    guj_person_name: 'યશ્વી મદન',
    contact_person_name: 'Mittal Aryansh',
    guj_contact_person_name: 'મિત્તલ આર્યનશ',
    vibhag_name: 'Mahesana',
    guj_vibhag_name: 'મહેસાણા',
    date: '09/03/2024',
    contact_no: '9876543210',
    email_id: 'yashvi@gmail.com',
    address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
    guj_address: '1777, જ્યુબિલી જંક્શન, ચડાસણા, બેચરાજી, ગુજરાત, 370133',
    interest: 'Inculcate Family & Human Values',
    guj_interest: 'પારિવારિક તથા માનવિય મુલ્યો નુ જતન',
    working_time_hrs: 'Daily / 2 hrs',
    guj_working_time_hrs: 'દૈનિક / 2 કલાક',
    type_of_sanstha: 'Dharmik Institutions',
    guj_type_of_sanstha: 'ધાર્મિક (મઠ મંદિર)',
    type_of_sanstha_value: 'dharmik',
    sanstha_name: 'Dhrangadhra 11 primary school',
    guj_sanstha_name: 'ધ્રાંગધ્રા 11 પ્રાથમિક શાળા',
    additional_notes: '--',
    profession: 'Teacher',
    guj_profession: 'શિક્ષક',
    organization_type: 'NGO',
    guj_organization_type: 'NGO',
  },
];
export const top100Films = [
  {
    title_name: 'The Lord of the Rings: The Return of the King',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12442003',
  },
  {
    title_name: 'The Lord of the Rings: The Fellowship of the Ring',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12442001',
  },
  {
    title_name: 'Star Wars: Episode V - The Empire Strikes Back',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12441980',
  },
  {
    title_name: 'The Lord of the Rings: The Two Towers',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12442002',
  },
  {
    title_name: 'Star Wars: Episode IV - A New Hope',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12441977',
  },
  {
    title_name: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12441964',
  },
  {
    title_name: 'Star Wars: Episode VI - Return of the Jedi',
    sub_title: 'sub title',
    last_name: 'last name',
    contact_no: '1234567890',
    year: '12441983',
  },
  {
    title_name: 'Eternal Sunshine of the Spotless Mind',
    sub_title: 'sub title',
    last_name: 'last hello name',
    contact_no: '1234567890',
    year: '12442004',
  },
];

export const team_management_user_dropdown = [
  {
    name: 'Aarav Agarwal',
    guj_name: 'આરવ અગ્રવાલ',
    contact: '9876543210',
    user_id: '001',
    id: '1',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Aanya Ahuja',
    guj_name: 'અનન્યા આહુજા',
    contact: '9876543210',
    user_id: '002',
    id: '2',
    allocated_id: '002',
    position_name: 'Sahsachiv',
    guj_position_name: 'સહ સચિવ',
  },
  {
    name: 'Barkha Nadkarni',
    guj_name: 'બરખા નાડકર્ણી',
    contact: '9876543210',
    user_id: '003',
    id: '3',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Gargee Panicker',
    guj_name: 'ગાર્ગી પનીકર',
    contact: '9876543210',
    user_id: '004',
    id: '4',
    allocated_id: '004',
    position_name: 'Educational Institutions',
    guj_position_name: 'શૈક્ષણિક સંસ્થા',
  },
  {
    name: 'Divit Nayar',
    guj_name: 'દિવિત નાયર',
    contact: '9876543210',
    user_id: '005',
    id: '5',
    allocated_id: '005',
    position_name: 'Socialist (NGOs)',
    guj_position_name: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
  },
  {
    name: 'Chandni Narayan',
    guj_name: 'ચાંદની નારાયણ',
    contact: '9876543210',
    user_id: '006',
    id: '6',
    allocated_id: '006',
    position_name: 'Youth Wings',
    guj_position_name: 'યુવા શક્તિ',
  },
  {
    name: 'Avishi Mukhopadhyay',
    guj_name: 'અવશી મુખોપાધ્યાય',
    contact: '9876543210',
    user_id: '007',
    id: '7',
    allocated_id: '007',
    position_name: 'Female Wings',
    guj_position_name: 'નારી શક્તિ',
  },
  {
    name: 'Gayatri Iyer',
    guj_name: 'ગાયત્રી અય્યર',
    contact: '9876543210',
    user_id: '008',
    id: '8',
    allocated_id: '008',
    position_name: 'Family Organizations',
    guj_position_name: 'પરિવાર ક્ષેત્ર',
  },
  {
    name: 'Yashvi Madan',
    guj_name: 'યશ્વી મદન',
    contact: '9876543210',
    user_id: '009',
    id: '9',
    allocated_id: '009',
    position_name: 'Corporate Houses',
    guj_position_name: 'કોર્પોરેટ સંપર્ક',
  },
  {
    name: 'Vipul Jethava',
    guj_name: 'વિપુલ જેઠવા',
    contact: '9876543210',
    user_id: '010',
    id: '0',
    allocated_id: '010',
    position_name: 'Media / Social Media',
    guj_position_name: 'પ્રચાર - પ્રસાર',
  },
  {
    name: 'Chintan Soya',
    guj_name: 'ચિંતન સોયા',
    contact: '9876543210',
    user_id: '011',
    id: '11',
    allocated_id: '011',
    position_name: 'Office',
    guj_position_name: 'કાર્યાલય',
  },
  {
    name: 'Bhupendra Sharma',
    guj_name: 'ભૂપેન્દ્ર શર્મા',
    contact: '9876543210',
    user_id: '012',
    id: '12',
    allocated_id: '012',
    position_name: 'IT',
    guj_position_name: 'IT',
  },
  {
    name: 'Vanraj  Thakor',
    guj_name: 'વનરાજ ઠાકોર',
    contact: '9876543210',
    user_id: '013',
    id: '13',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Mukesh Vasani',
    guj_name: 'મુકેશ વસાણી',
    contact: '9876543210',
    user_id: '014',
    id: '14',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Gargee Panicker',
    guj_name: 'ગાર્ગી પનીકર',
    contact: '9876543210',
    user_id: '015',
    id: '15',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Krishana Rathod',
    guj_name: 'ક્રિષ્ના રાઠોડ',
    contact: '9876543210',
    user_id: '016',
    id: '16',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
  {
    name: 'Gaurav Gupta',
    guj_name: 'ગૌરવ ગુપ્તા',
    contact: '9876543210',
    user_id: '017',
    id: '17',
    allocated_id: null,
    position_name: null,
    guj_position_name: null,
  },
];

export const ROLES_Radio = [
  // { value: 'prant', label: 'Prant', guj_label: 'પ્રાંત', view_array_obj: [ROLES.prant] },
  // {
  //   value: 'vibhag',
  //   label: 'Vibhag',
  //   guj_label: 'વિભાગ',
  //   view_array_obj: [ROLES.superAdmin, ROLES.prant],
  // },
  {
    value: 'jilla',
    label: 'District',
    guj_label: 'જિલ્લા',
    view_array_obj: [ROLES.superAdmin, ROLES.prant, ROLES.vibhag],
  },
  {
    value: 'taluka',
    label: 'Taluka',
    guj_label: 'તાલુકો',
    view_array_obj: [ROLES.superAdmin, ROLES.prant, ROLES.jilla, ROLES.vibhag],
  },
  {
    value: 'admin',
    label: 'Admin',
    guj_label: 'એડમિન',
    view_array_obj: [ROLES.superAdmin, ROLES.prant, ROLES.jilla, ROLES.vibhag, ROLES.taluka],
  },
  {
    value: 'user',
    label: 'User',
    guj_label: 'વપરાશકર્તા',
    view_array_obj: [ROLES.superAdmin, ROLES.prant, ROLES.jilla, ROLES.vibhag, ROLES.taluka],
  },
];

export const fair_table_data = [
  {
    id: '001',
    value: 'dharmik',
    aayam_javabdari: 'Dharmik Institutions',
    guj_aayam_javabdari: 'ધાર્મિક (મઠ મંદિર)',
    name: 'Aarav Agarwal',
    guj_name: 'આરવ અગ્રવાલ',
    contact: '9876543210',
  },
  {
    id: '002',
    value: 'shikshan_sanstha_sampark',
    aayam_javabdari: 'Educational Institutions',
    guj_aayam_javabdari: 'શૈક્ષણિક સંસ્થા',
    name: 'Aanya Ahuja',
    guj_name: 'અનન્યા આહુજા',
    contact: '9876543210',
  },
  {
    id: '003',
    value: 'samajik_sanstha_sampark',
    aayam_javabdari: 'Socialist (NGOs)',
    guj_aayam_javabdari: 'સામાજિક સંસ્થા (NGO/ સેવા) સંપર્ક',
    name: 'Barkha Nadkarni',
    guj_name: 'બરખા નાડકર્ણી',
    contact: '9876543210',
  },
  {
    id: '004',
    value: 'yuva_shakti',
    aayam_javabdari: 'Youth Wings',
    guj_aayam_javabdari: 'યુવા શક્તિ',
    name: 'Gargee Panicker',
    guj_name: 'ગાર્ગી પનીકર',
    contact: '9876543210',
  },
  {
    id: '005',
    value: 'nari_shakti',
    aayam_javabdari: 'Female Wings',
    guj_aayam_javabdari: 'નારી શક્તિ',
    name: 'Divit Nayar',
    guj_name: 'દિવિત નાયર',
    contact: '9876543210',
  },
  {
    id: '006',
    value: 'parivar_kshetra',
    aayam_javabdari: 'Family Organizations',
    guj_aayam_javabdari: 'પરિવાર ક્ષેત્ર',
    name: 'Chandni Narayan',
    guj_name: 'ચાંદની નારાયણ',
    contact: '9876543210',
  },
  {
    id: '007',
    value: 'corporate_sampark',
    aayam_javabdari: 'Corporate Houses',
    guj_aayam_javabdari: 'કોર્પોરેટ સંપર્ક',
    name: 'Avishi Mukhopadhyay',
    guj_name: 'અવશી મુખોપાધ્યાય',
    contact: '9876543210',
  },
  {
    id: '008',
    value: 'prachar_jaherat',
    aayam_javabdari: 'Media / Social Media',
    guj_aayam_javabdari: 'પ્રચાર - પ્રસાર',
    name: 'Gayatri Iyer',
    guj_name: 'ગાયત્રી અય્યર',
    contact: '9876543210',
  },
  {
    id: '009',
    value: 'karyalaya',
    aayam_javabdari: 'Office',
    guj_aayam_javabdari: 'કાર્યાલય',
    name: 'Yashvi Madan',
    guj_name: 'યશ્વી મદન',
    contact: '9876543210',
  },
  {
    id: '010',
    value: 'it',
    aayam_javabdari: 'IT',
    guj_aayam_javabdari: 'IT',
    name: 'Vipul Jethava',
    guj_name: 'વિપુલ જેઠવા',
    contact: '9876543210',
  },
];
// Fair Type : -----
export const fair_types_option = [
  { value: 'fair2025', label: 'Fair 2025', guj_label: 'મેળા આયોજન 2025' },
  // { value: 'individual', label: 'Mela Aayojan Samiti', guj_label: 'મેળા આયોજન સમિતિ' },
  // {
  //   value: 'organization',
  //   label: 'Pujaniya Santo Marg Darshak Mandal',
  //   guj_label: 'પૂજનીય સંતો માર્ગ દર્શન મંડળ',
  // },
  // { value: 'organization', label: 'Shresthi Shamit', guj_label: 'શ્રેષ્ઠી શમિત' },
  // { value: 'sankalan_karyakarta', label: 'Sankalan Karyakarta', guj_label: 'સંકલન કાર્યકર્તા' },
];

export const professions_options = [
  { value: 'doctor', label: 'Doctor', guj_label: 'ડોક્ટર (Doktar)' },
  { value: 'engineer', label: 'Engineer', guj_label: 'ઇજનીયર (Engineer)' },
  { value: 'teacher', label: 'Teacher', guj_label: 'શિક્ષક (Shikshak)' },
  { value: 'lawyer', label: 'Lawyer', guj_label: 'વકીલ (Vakil)' },
  { value: 'nurse', label: 'Nurse', guj_label: 'નર્સ (Nars)' },
  { value: 'artist', label: 'Artist', guj_label: 'કલાકાર (Kalakar)' },
  { value: 'chef', label: 'Chef', guj_label: 'રસોઈના શૈક્ષિક (Rasoina Shaikshik)' },
  { value: 'architect', label: 'Architect', guj_label: 'સ્થાપત્યકાર (Sthapatyakar)' },
  { value: 'accountant', label: 'Accountant', guj_label: 'લાખાંતર (Lakhatar)' },
  { value: 'musician', label: 'Musician', guj_label: 'સંગીતકાર (Sangeetkar)' },
  { value: 'scientist', label: 'Scientist', guj_label: 'વિજ્ઞાની (Vignani)' },
  {
    value: 'police_officer',
    label: 'Police Officer',
    guj_label: 'પોલીસ અધિકારી (Police Adhikari)',
  },
  { value: 'firefighter', label: 'Firefighter', guj_label: 'અગ્નિશામક (Agnishamak)' },
  { value: 'pilot', label: 'Pilot', guj_label: 'પાયલટ (Pilot)' },
  { value: 'farmer', label: 'Farmer', guj_label: 'ખેડુત (Khedut)' },
  { value: 'other', label: 'Other', guj_label: 'અન્ય (Other)' },
];

export const sanstha_details_data = {
  sanstha_ni_nondhani_number: 234567890,
  sanstha_nu_karyakṣhetra: 989898,
  sanstha_sanchalit_sevakarya: [
    { sevakarya_name: 'Vishanu Bhatt', labharthi_sankhya: 20 },
    { sevakarya_name: 'Chandni Narayan', labharthi_sankhya: 15 },
  ],
  adhyaksa_name: 'Barkha Nadkarni',
  adhyaksa_address: '1777, Jubilee Junction, Chadasana, Bechraji, Gujarat, 370133',
  mahamantri_name: 'Aarav Agarwal',
  mahamantri_address: '1666, Mellow Mansion, Vadasan, Visnagar, Gujarat, 370127',
  sandarbh_vyakti: 'Ziva Maheshwari',
};

export const sub_event_details = [
  {
    sections: 'Navsari',
    guj_sections: 'નવસારી',
    total_ongoing_program: 83,
    total_users: 75,
  },
  {
    sections: 'Surat',
    guj_sections: 'સુરત',
    total_ongoing_program: 67,
    total_users: 73,
  },
  {
    sections: 'Vadodra',
    guj_sections: 'વડોદરા',
    total_ongoing_program: 42,
    total_users: 48,
  },
  {
    sections: 'Nadiyad',
    guj_sections: 'નડિયાદ',
    total_ongoing_program: 43,
    total_users: 5,
  },
  {
    sections: 'Karnavati - East (ahmedabad)',
    guj_sections: 'કર્ણાવતી - પૂર્વ (અમદાવાદ)',
    total_ongoing_program: 16,
    total_users: 75,
  },
  {
    sections: 'Karnavati - West (ahmedabad)',
    guj_sections: 'કર્ણાવતી - પશ્ચિમ (અમદાવાદ)',
    total_ongoing_program: 24,
    total_users: 68,
  },
  {
    sections: 'Gandhinagar',
    guj_sections: 'ગાંધીનગર',
    total_ongoing_program: 71,
    total_users: 69,
  },
  {
    sections: 'Mahesana',
    guj_sections: 'મહેસાણા',
    total_ongoing_program: 39,
    total_users: 72,
  },
  {
    sections: 'Banashkantha',
    guj_sections: 'બનાસકાંઠા',
    total_ongoing_program: 95,
    total_users: 28,
  },
  {
    sections: 'Surendranagar',
    guj_sections: 'સુરેન્દ્રનગર',
    total_ongoing_program: 23,
    total_users: 61,
  },
  {
    sections: 'Bhavnagar',
    guj_sections: 'ભાવનગર',
    total_ongoing_program: 34,
    total_users: 97,
  },
  {
    sections: 'Rajkot',
    guj_sections: 'રાજકોટ',
    total_ongoing_program: 20,
    total_users: 91,
  },
  {
    sections: 'Junagadh',
    guj_sections: 'જુનાગઢ',
    total_ongoing_program: 44,
    total_users: 29,
  },
  {
    sections: 'Kachh',
    guj_sections: 'કચ્છ',
    total_ongoing_program: 94,
    total_users: 50,
  },
];
