import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import AuthClassicLayout from 'src/layouts/auth/classic';
import AddGameRegistrationMain from 'src/pages/HSSF/gameRegistration/add-game-registration';
import GameRegistrationMain from 'src/pages/HSSF/gameRegistration/game-registration';

import { paths } from '../paths';

export const hssfGameRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.gameRegistration.root,
        children: [
          {
            element: (
              <AuthClassicLayout>
                <GameRegistrationMain />
              </AuthClassicLayout>
            ),

            index: true,
          },
        ],
      },
      {
        path: paths.hssfPaths.gameRegistration.add,
        element: <AddGameRegistrationMain />,
      },
    ],
  },
];
