import { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { paths } from 'src/routes/paths';
import { Box } from '@mui/material';
import { useLocales } from 'src/locales';
import { alpha, useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router';
import NewGameRegistrationForm from './new-game-registration-form';

export default function GameRegistrationView() {
  const locales = useLocales();
  const { pathname } = useLocation();
  const handleChangeLang = useCallback(
    (newLang) => {
      locales.onChangeLang(newLang);
    },
    [locales]
  );
  // ------------------------------------------------------------------------------------------------------------------------------ language-local HSSF-START
  const theme = useTheme();
  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">{locales.t('game_registration.title')}</Typography>
      {[paths.auth.jwt.register, paths.hssfPaths.familyRegistration.root].includes(pathname)}
      <Typography variant="h6">
        હિન્દુ આધ્યાત્મિક અને સેવા સંસ્થાન - ગુજરાત દ્વારા આયોજીત હિન્દુ આધ્યાત્મિક સેવા મેળામાં
        સેવાકીય કાર્ય કરતી વિવિધ સંસ્થાઓની માહિતી એકત્રિત કરવા હેતુ બનાવવામાં આવેલું ફોર્મ
      </Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {locales.t('change_language')} </Typography>
        <Box
          sx={{
            margin: '0',
            fontWeight: '600',
            lineHeight: '1.5714285714285714',
            fontSize: '0.875rem',
            fontFamily: 'Public Sans,sans-serif',
            color: alpha(theme.palette.primary.main, 1),
            WebkitTextDecoration: 'none',
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          onClick={() => handleChangeLang(locales.currentLang.value === 'en' ? 'guj' : 'en')}
        >
          {locales.currentLang.value === 'en' ? 'Gujarati' : 'English'}
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      {renderHead}
      <NewGameRegistrationForm />
      {/* {renderTerms} */}
    </>
  );
}
